import { IDropdownOption } from "interfaces/Common";
import Dropdown from "components/Dropdowns/Dropdown/Dropdown";
import { ActiveStatus } from "enums/Common";

interface StatusDropdownProps {
  status?: ActiveStatus;
  onChange: (value: ActiveStatus | undefined) => void;
}

const options: IDropdownOption<ActiveStatus>[] = [
  {
    label: "Active",
    value: ActiveStatus.Active,
  },
  {
    label: "Inactive",
    value: ActiveStatus.Inactive,
  },
];

const StatusDropdown = (props: StatusDropdownProps) => {
  const { status, onChange } = props;

  return (
    <div className="min-w-[270px]">
      <Dropdown
        hideClearButton
        label="Select status"
        options={options}
        selected={status}
        onChange={(value) => onChange(value)}
      />
    </div>
  );
};

export default StatusDropdown;
