import { atom, selector } from "recoil";
import { IAuth } from "interfaces/Auth";
import LocalStorageService from "services/LocalStorageService";
import { checkRefreshTokenExpiration } from "helpers/CommonHelper";
import {
  hasClientAdminRole,
  hasSuperAdminRole,
} from "helpers/PermissionsHelper";

// check before auth is initialized from local storage
checkRefreshTokenExpiration();

// Atoms:
export const Auth = atom<IAuth | undefined>({
  key: "Auth",
  default: LocalStorageService.getAuth(),
});

export const AccountAuth = atom<IAuth | undefined>({
  key: "AccountAuth",
  default: LocalStorageService.getAccountAuth(),
});

// Selectors:
export const IsClientAdmin = selector<boolean>({
  key: "IsClientAdmin",
  get: ({ get }) => {
    const auth = get(Auth);
    const permissionMask = auth?.initialData?.permissions;

    return hasClientAdminRole(permissionMask);
  },
});

export const IsSuperAdmin = selector<boolean>({
  key: "IsSuperAdmin",
  get: ({ get }) => {
    const auth = get(Auth);
    const permissionMask = auth?.initialData?.permissions;

    return hasSuperAdminRole(permissionMask);
  },
});

export const IsBillingCompany = selector<boolean>({
  key: "IsBillingCompany",
  get: ({ get }) => {
    const auth = get(Auth);

    return auth?.initialData?.isBillingCompanyUser ?? false;
  },
});

export const IsInternalClient = selector<boolean>({
  key: "IsInternalClient",
  get: ({ get }) => {
    const auth = get(Auth);

    return auth?.initialData?.isInternalClient ?? false;
  },
});
