import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";
import { API } from "enums/API";
import { LocalStorage, RequestHeader } from "enums/Common";
import { getLoginRouteWithRedirect } from "helpers/RoutingHelper";
import LocalStorageService from "services/LocalStorageService";
import AuthService from "services/AuthService";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const auth = LocalStorageService.getAuth();
  const accountAuth = LocalStorageService.getAccountAuth();

  if (!auth) return config;

  // attach token on request
  if (!config.headers) config.headers = {};
  const refreshToken = accountAuth?.["refreshToken"] ?? auth["refreshToken"];
  const accessToken = accountAuth?.["token"] ?? auth["token"];

  /* 
  do not attach token from storage on initial data request,
  we are always providing new token to this request
  and we set storage after initial data request (after we combine data from login and initial data requests)
  */
  if (config.url !== API.UserInitialData)
    config.headers[RequestHeader.ApiToken] =
      config.url === API.RefreshToken ? refreshToken : accessToken;

  // decamelize parameters keys
  if (config.params) config.params = decamelizeKeys(config.params);

  // decamelize body keys
  if (config.data) config.data = decamelizeKeys(config.data);

  return config;
});

// camelize data keys from response
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data) {
      response.data = camelizeKeys(response.data, {
        // don't camelize keys that have dash (camelize will remove dash from key)
        process: (key, process) => {
          if (key.includes("-")) return key;

          return process(key);
        },
      });
    }

    return response;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config;

    // Request fails with 401 status code when request is sent with:
    // - invalid token
    // - expired token
    // - invalid credentials (Login endpoint)
    // After 401, we send request to Refresh token endpoint except for endpoints stored in excludedRefreshAPIs
    // User is logged out and redirected to login page if Refresh token request fails
    const excludedRefreshAPIs: API[] = [API.RefreshToken, API.Login];

    if (
      error.response &&
      originalRequest &&
      !excludedRefreshAPIs.includes(originalRequest.url as API)
    ) {
      const auth = LocalStorageService.getAuth();
      const accountAuth = LocalStorageService.getAccountAuth();

      if (error.response.status === 401) {
        const refreshToken =
          accountAuth?.["refreshToken"] ?? auth?.["refreshToken"];

        try {
          const { data } = await axios.post(
            process.env.REACT_APP_API_BASE_URL + API.RefreshToken,
            null,
            { headers: { "api-token": refreshToken } }
          );

          const newData = {
            ...auth,
            token: data.token,
            expiresOn: data.expires_on,
          };

          localStorage.setItem(
            accountAuth ? LocalStorage.AccountAuth : LocalStorage.Auth,
            JSON.stringify(newData)
          );

          return axiosInstance({
            ...originalRequest,
            headers: { ...originalRequest.headers, "api-token": data.token },
          });
        } catch (error) {
          AuthService.logout();
          LocalStorageService.removeAuth();
          LocalStorageService.removeAccountAuth();

          const redirectURL = getLoginRouteWithRedirect(window.location);
          window.location.href = redirectURL;
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
