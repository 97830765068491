import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, generatePath, useSearchParams } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { URLRoutes } from "enums/Routing";
import { ITableColumn } from "interfaces/Common";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import TableInputFilter from "components/TableInputFilter/TableInputFilter";
import TableDatepickerFilter from "components/TableDatepickerFilter/TableDatepickerFilter";
import { PatientsOperationsSection } from "enums/Patient";
import PatientOperationsService from "services/PatientOperationsService";
import useSnackbar from "hooks/useSnackbar";
import useFetchTableData from "hooks/useFetchTableData";
import { OrderDirection, PageSize } from "enums/Common";
import Loading from "components/Loading/Loading";
import {
  IPatientOperations,
  IPatientOperationsFilters,
} from "interfaces/PatientOperations";
import {
  createUrlParams,
  formatDashDate,
  formatName,
  searchParamsToObject,
} from "helpers/CommonHelper";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import styles from "./PatientsOperationsTable.module.scss";

interface PatientsOperationsTableProps {
  sections: PatientsOperationsSection[];
}

const PatientsOperationsTable = (props: PatientsOperationsTableProps) => {
  const { sections } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<IPatientOperationsFilters>({
    ...searchParamsToObject(searchParams),
    _total_count: 1,
    page_size: PageSize.Default,
    page: 1,
    _order_by: searchParams.get("_order_by") || `dob ${OrderDirection.Desc}`,
  });

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  const { snackbarComponent, setSnackbarError } = useSnackbar();
  const { data, loading, scrollableParentRef, lastElementRef } =
    useFetchTableData<IPatientOperations, IPatientOperationsFilters>({
      filters,
      onPageIncrement,
      fetchAPI: PatientOperationsService.getPatients,
      onError: setSnackbarError,
    });

  useEffect(() => {
    const {
      page,
      page_size,
      _total_count,
      // push only props that can be filtered
      ...urlParams
    } = filters;

    setSearchParams(createUrlParams(urlParams), { replace: true });
  }, [filters, setSearchParams]);

  const showInformationSection = sections.includes(
    PatientsOperationsSection.Information
  );
  const showOPSSection = sections.includes(
    PatientsOperationsSection.LabAtHomeOPS
  );
  const showSupportSection = sections.includes(
    PatientsOperationsSection.Support
  );
  const showBillingSection = sections.includes(
    PatientsOperationsSection.Billing
  );

  /*   
  odd groups should have white (set default in css) color,
  even groups should have blue color 
  */
  const addBlueBgToOpsCells = showInformationSection;
  const addBlueBgToSupportCells =
    [showInformationSection, showOPSSection].filter((x) => x).length === 1;
  const addBlueBgToBillingCells = [1, 3].includes(
    [showInformationSection, showOPSSection, showSupportSection].filter(
      (x) => x
    ).length
  );

  const tableColumns = useMemo(
    (): ITableColumn[] => [
      { label: "Patient Name", sortProperty: "last_name" },
      ...(showInformationSection
        ? [
            { label: "DOB", sortProperty: "dob" },
            { label: "Email", sortProperty: "email" },
            { label: "Street", sortProperty: "street" },
            { label: "City", sortProperty: "city" },
            { label: "State", sortProperty: "state" },
            { label: "ZIP", sortProperty: "post_code" },
            { label: "Phone Number", sortProperty: "phone" },
            { label: "Account", sortProperty: "client_name" },
            { label: "Ordering Clinician", sortProperty: "ordering_clinician" },
          ]
        : []),
      ...(showOPSSection
        ? [
            { label: "Shipped", sortProperty: "sensor_shipped_on" },
            { label: "Received", sortProperty: "sensor_received_on" },
            { label: "Activation", sortProperty: "sensor_is_activated" },
            { label: "First Use", sortProperty: "order_ft_created_on" },
            { label: "Last Use", sortProperty: "order_lt_created_on" },
            { label: "30-day Frequency" },
          ]
        : []),
      ...(showSupportSection
        ? [{ label: "Welcome email" }, { label: "Notes & Attachments" }]
        : []),
      ...(showBillingSection
        ? [
            { label: "Order Received", sortProperty: "has_order" },
            { label: "Order Expires", sortProperty: "order_end_date" },
            { label: "Demographics Received" },
            { label: "Insurance" },
            { label: "Monitoring Start Date" },
            { label: "Order/Demo to RDL" },
          ]
        : []),
    ],
    [
      showInformationSection,
      showOPSSection,
      showSupportSection,
      showBillingSection,
    ]
  );

  return (
    <>
      <div className={`h-full relative -mr-5 ${styles.table_wrapper}`}>
        <TableContainer ref={scrollableParentRef}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                {showInformationSection && (
                  <TableCell colSpan={9}>Patient Information</TableCell>
                )}

                {showOPSSection && (
                  <TableCell
                    colSpan={6}
                    className={addBlueBgToOpsCells ? "!bg-pattensBlue4" : ""}
                  >
                    Lab @ Home OPS
                  </TableCell>
                )}

                {showSupportSection && (
                  <TableCell
                    colSpan={2}
                    className={
                      addBlueBgToSupportCells ? "!bg-pattensBlue4" : ""
                    }
                  >
                    Patient Support
                  </TableCell>
                )}

                {showBillingSection && (
                  <TableCell
                    colSpan={6}
                    className={
                      addBlueBgToBillingCells ? "!bg-pattensBlue4" : ""
                    }
                  >
                    Billing
                  </TableCell>
                )}
              </TableRow>
              <TableRow>
                {tableColumns.map(({ label, sortProperty }, index) => (
                  <TableCell key={index}>
                    {sortProperty ? (
                      <TableSortLabel
                        whiteTheme
                        label={label}
                        sortProperty={sortProperty}
                        orderBy={filters._order_by}
                        onSort={(value) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            _order_by: value,
                          }))
                        }
                      />
                    ) : (
                      label
                    )}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>
                  <TableInputFilter
                    placeholder="Search by name"
                    value={filters.name}
                    onChange={(name) =>
                      setFilters((prev) => ({ ...prev, page: 1, name }))
                    }
                  />
                </TableCell>

                {showInformationSection && (
                  <>
                    <TableCell>
                      <TableDatepickerFilter
                        removeNextDay
                        value={filters.dob}
                        onChange={(dob) =>
                          setFilters((prev) => ({ ...prev, page: 1, dob }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableInputFilter
                        placeholder="Search by email"
                        value={filters.email}
                        onChange={(email) =>
                          setFilters((prev) => ({ ...prev, page: 1, email }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableInputFilter
                        placeholder="Search by street"
                        value={filters.street}
                        onChange={(street) =>
                          setFilters((prev) => ({ ...prev, page: 1, street }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableInputFilter
                        placeholder="Search by city"
                        value={filters.city}
                        onChange={(city) =>
                          setFilters((prev) => ({ ...prev, page: 1, city }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableInputFilter
                        placeholder="Search by state"
                        value={filters.state}
                        onChange={(state) =>
                          setFilters((prev) => ({ ...prev, page: 1, state }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableInputFilter
                        placeholder="Search by zip"
                        value={filters.postCode}
                        onChange={(postCode) =>
                          setFilters((prev) => ({ ...prev, page: 1, postCode }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableInputFilter
                        placeholder="Search by number"
                        value={filters.phone}
                        onChange={(phone) =>
                          setFilters((prev) => ({ ...prev, page: 1, phone }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableInputFilter
                        placeholder="Search by account"
                        value={filters.clientName}
                        onChange={(clientName) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            clientName,
                          }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableInputFilter
                        placeholder="Search by name"
                        value={filters.orderingClinician}
                        onChange={(orderingClinician) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            orderingClinician,
                          }))
                        }
                      />
                    </TableCell>
                  </>
                )}

                {showOPSSection && (
                  <>
                    <TableCell>
                      <TableDatepickerFilter
                        value={filters.sensorShippedOn}
                        onChange={(sensorShippedOn) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            sensorShippedOn,
                          }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableDatepickerFilter
                        value={filters.sensorReceivedOn}
                        onChange={(sensorReceivedOn) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            sensorReceivedOn,
                          }))
                        }
                      />
                    </TableCell>
                    <TableCell />
                    <TableCell>
                      <TableDatepickerFilter
                        value={filters.orderFtCreatedOn}
                        onChange={(orderFtCreatedOn) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            orderFtCreatedOn,
                          }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableDatepickerFilter
                        value={filters.orderLtCreatedOn}
                        onChange={(orderLtCreatedOn) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            orderLtCreatedOn,
                          }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {/* Commeneted until implemented on API side */}
                      {/* <TableInputFilter
                        placeholder="Search by frequency"
                        value={undefined}
                        onChange={() => {}}
                      /> */}
                    </TableCell>
                  </>
                )}

                {showSupportSection && (
                  <>
                    <TableCell />
                    <TableCell />
                  </>
                )}

                {showBillingSection && (
                  <>
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      {/* Commeneted until implemented on API side */}
                      {/* <TableDatepickerFilter
                        value={undefined}
                        onChange={() => {}}
                      /> */}
                    </TableCell>
                    <TableCell>
                      {/* Commeneted until implemented on API side */}
                      {/* <TableInputFilter
                        placeholder="Search by insurance"
                        value={undefined}
                        onChange={() => {}}
                      /> */}
                    </TableCell>
                    <TableCell />
                    <TableCell />
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(
                (
                  {
                    firstName,
                    lastName,
                    dob,
                    city,
                    email,
                    hasOrder,
                    id,
                    orderEndDate,
                    postCode,
                    phone,
                    clientName,
                    orderFtCreatedOn,
                    orderLtCreatedOn,
                    orderingClinician,
                    sensorIsActivated,
                    sensorReceivedOn,
                    sensorShippedOn,
                    street,
                    state,
                  },
                  index
                ) => (
                  <TableRow
                    key={id}
                    ref={index === data.length - 1 ? lastElementRef : null}
                  >
                    <TableCell>{formatName({ firstName, lastName })}</TableCell>

                    {showInformationSection && (
                      <>
                        <TableCell>{formatDashDate(dob)}</TableCell>
                        <TableCell>{email ?? "-"}</TableCell>
                        <TableCell>{street ?? "-"}</TableCell>
                        <TableCell>{city ?? "-"}</TableCell>
                        <TableCell>{state ?? "-"}</TableCell>
                        <TableCell>{postCode ?? "-"}</TableCell>
                        <TableCell>{phone ?? "-"}</TableCell>
                        <TableCell>{clientName}</TableCell>
                        <TableCell>{orderingClinician ?? "-"}</TableCell>
                      </>
                    )}

                    {showOPSSection && (
                      <>
                        <TableCell
                          className={
                            addBlueBgToOpsCells ? "!bg-pattensBlue4" : ""
                          }
                        >
                          {formatDashDate(sensorShippedOn)}
                        </TableCell>
                        <TableCell
                          className={
                            addBlueBgToOpsCells ? "!bg-pattensBlue4" : ""
                          }
                        >
                          {formatDashDate(sensorReceivedOn)}
                        </TableCell>
                        <TableCell
                          className={
                            addBlueBgToOpsCells ? "!bg-pattensBlue4" : ""
                          }
                        >
                          {sensorIsActivated ? "Activated" : "Not Activated"}
                        </TableCell>
                        <TableCell
                          className={
                            addBlueBgToOpsCells ? "!bg-pattensBlue4" : ""
                          }
                        >
                          {formatDashDate(orderFtCreatedOn)}
                        </TableCell>
                        <TableCell
                          className={
                            addBlueBgToOpsCells ? "!bg-pattensBlue4" : ""
                          }
                        >
                          {formatDashDate(orderLtCreatedOn)}
                        </TableCell>
                        <TableCell
                          className={
                            addBlueBgToOpsCells ? "!bg-pattensBlue4" : ""
                          }
                        >
                          -
                        </TableCell>
                      </>
                    )}

                    {showSupportSection && (
                      <>
                        <TableCell
                          className={
                            addBlueBgToSupportCells ? "!bg-pattensBlue4" : ""
                          }
                        >
                          -
                        </TableCell>
                        <TableCell
                          className={
                            addBlueBgToSupportCells ? "!bg-pattensBlue4" : ""
                          }
                        >
                          <Link
                            className="text-pelorous hover:underline"
                            to={generatePath(
                              URLRoutes.PatientOperationsAllActivity,
                              {
                                patientId: id,
                              }
                            )}
                          >
                            View Activity
                          </Link>
                        </TableCell>
                      </>
                    )}

                    {showBillingSection && (
                      <>
                        <TableCell
                          className={
                            addBlueBgToBillingCells ? "!bg-pattensBlue4" : ""
                          }
                        >
                          {hasOrder ? "Yes" : "No"}
                        </TableCell>
                        <TableCell
                          className={
                            addBlueBgToBillingCells ? "!bg-pattensBlue4" : ""
                          }
                        >
                          {formatDashDate(orderEndDate)}
                        </TableCell>
                        <TableCell
                          className={
                            addBlueBgToBillingCells ? "!bg-pattensBlue4" : ""
                          }
                        >
                          -
                        </TableCell>
                        <TableCell
                          className={
                            addBlueBgToBillingCells ? "!bg-pattensBlue4" : ""
                          }
                        >
                          -
                        </TableCell>
                        <TableCell
                          className={
                            addBlueBgToBillingCells ? "!bg-pattensBlue4" : ""
                          }
                        >
                          -
                        </TableCell>
                        <TableCell
                          className={
                            addBlueBgToBillingCells ? "!bg-pattensBlue4" : ""
                          }
                        >
                          -
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {!loading && !data.length && (
          <div className="mt-7">
            <NoTableItems icon="icon_search" message="No results found" />
          </div>
        )}

        <Loading loading={loading} />
      </div>

      {snackbarComponent}
    </>
  );
};

export default PatientsOperationsTable;
