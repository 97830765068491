import { EChartsOption } from "echarts";
import RTMAnalysisChartWrapper from "components/RTMAnalysis/RTMAnalysisChartWrapper/RTMAnalysisChartWrapper";
import { Color } from "enums/Common";
import { IRTMAnalysisData } from "interfaces/KPI";

interface OrdersByDurationChartProps {
  data: IRTMAnalysisData["byDuration"];
}

const OrdersByDurationChart = ({ data }: OrdersByDurationChartProps) => {
  const xValues = data.map(({ months }) => months);
  const yValues = data.map(({ cnt }) => cnt);

  const getOptions = (): EChartsOption => {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{b}: {c}", // name: value
      },
      grid: {
        top: 5,
        bottom: 20,
        left: 5,
        right: 0,
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 12,
            lineHeight: 15,
            fontFamily: "Cabin",
            color: Color.Nero,
          },
          data: xValues,
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            fontSize: 10,
            lineHeight: 13,
            fontFamily: "Cabin",
            color: Color.Nero,
          },
          splitLine: {
            lineStyle: { color: Color.VeryLightGrey },
          },
          axisTick: { show: true },
        },
      ],
      series: [
        {
          name: "Months",
          barWidth: 6,
          type: "bar",
          color: Color.Endeavour,
          emphasis: {
            focus: "series",
          },
          data: yValues,
        },
      ],
      legend: {
        icon: "square",
        itemWidth: 12,
        bottom: -8,
        itemGap: 42,
        textStyle: {
          fontSize: 12,
          lineHeight: 15,
          fontFamily: "Cabin",
          color: Color.Nero,
        },
      },
      minInterval: 1,
    };
  };

  return (
    <RTMAnalysisChartWrapper
      isEmpty={!data.length}
      title="Current RTM Orders by Duration"
      chartOptions={getOptions()}
    />
  );
};

export default OrdersByDurationChart;
