import { EChartsOption } from "echarts";
import ReactECharts from "echarts-for-react";
import { Color } from "enums/Common";
import { IKPIAccountData } from "interfaces/KPI";
import {
  get12MonthsDataZoomParams,
  getMonthAndYearXValues,
  sortMonthAndYearData,
} from "helpers/KPIChartHelper";

interface AccountAnalysisChartProps {
  data: IKPIAccountData["overview"]["products"];
}

const AccountAnalysisChart = ({ data }: AccountAnalysisChartProps) => {
  const sortedData = sortMonthAndYearData({
    data,
    monthKey: "m",
    yearKey: "y",
  });

  const atHomeValues = sortedData.map(({ ah }) => ah);
  const inClinicValues = sortedData.map(({ lab }) => lab);
  const remoteValues = sortedData.map(({ rt }) => rt);
  const xValues = getMonthAndYearXValues({
    data: sortedData,
    monthKey: "m",
    yearKey: "y",
  });

  const getOptions = (): EChartsOption => {
    return {
      grid: {
        top: 5,
        bottom: 35,
        left: 5,
        right: 0,
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 12,
            lineHeight: 15,
            fontFamily: "Cabin",
            color: Color.Nero,
          },
          data: xValues,
        },
      ],
      yAxis: [
        {
          type: "value",
          minInterval: 1,
          axisLabel: {
            fontSize: 10,
            lineHeight: 13,
            fontFamily: "Cabin",
            color: Color.Nero,
          },
          splitLine: {
            lineStyle: { color: Color.VeryLightGrey },
          },
          axisTick: { show: true },
        },
      ],
      series: [
        {
          name: "Lab at Home",
          barWidth: 24,
          type: "bar",
          stack: "Ad",
          color: Color.Endeavour,
          emphasis: {
            focus: "series",
          },
          data: atHomeValues,
        },
        {
          name: "Remote",
          barWidth: 24,
          type: "bar",
          stack: "Ad",
          color: Color.Tumbleweed,
          emphasis: {
            focus: "series",
          },
          data: remoteValues,
        },
        {
          name: "In Clinic",
          barWidth: 24,
          type: "bar",
          stack: "Ad",
          color: Color.TropicalRainForest,
          emphasis: {
            focus: "series",
          },
          data: inClinicValues,
        },
      ],
      legend: {
        icon: "square",
        itemWidth: 12,
        bottom: -8,
        itemGap: 42,
        textStyle: {
          fontSize: 12,
          lineHeight: 15,
          fontFamily: "Cabin",
          color: Color.Nero,
        },
      },
      dataZoom: get12MonthsDataZoomParams({ data, bottom: 25 }),
    };
  };

  return (
    <div className="h-[30vh] min-h-[200px] px-4 py-3 rounded-[20px] bg-endeavour/10 lg:grow lg:w-0">
      <ReactECharts
        style={{ height: "100%", width: "100%" }}
        option={getOptions()}
      />
    </div>
  );
};

export default AccountAnalysisChart;
