import { AxiosResponse } from "axios";
import axios from "config/axios";
import { API } from "enums/API";
import {
  IAccountStatistics,
  IAccountStatisticsFilters,
  IKPIAccountData,
  IRTMAnalysisData,
} from "interfaces/KPI";

class KPIService {
  getAccountData = (
    signal?: AbortSignal
  ): Promise<AxiosResponse<IKPIAccountData>> => {
    return axios.get(API.KPIAccountData, { signal });
  };

  getRTMAnalysisData = (
    params: { clientId?: string },
    signal?: AbortSignal
  ): Promise<AxiosResponse<IRTMAnalysisData>> => {
    return axios.get(API.KPIRTMAnalysisData, { params, signal });
  };

  getRTMStatistics = (
    params: IAccountStatisticsFilters,
    signal?: AbortSignal
  ): Promise<AxiosResponse<IAccountStatistics[]>> => {
    return axios.get(API.KPIRTMStatistics, { params, signal });
  };

  getClients = (
    params: { name: string },
    signal?: AbortSignal
  ): Promise<AxiosResponse<{ [key: string]: string }>> => {
    return axios.get(API.KPIClients, { params, signal });
  };
}

export default new KPIService();
