import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Outlet,
  useOutletContext,
  useParams,
  matchPath,
  useLocation,
  generatePath,
  useSearchParams,
} from "react-router-dom";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { IPatient, IPatientLayoutFilters } from "interfaces/Patient";
import PatientService from "services/PatientService";
import OrderService from "services/OrderService";
import { INavItem } from "interfaces/Common";
import { URLRoutes } from "enums/Routing";
import Navbar from "components/Navbar/Navbar";
import useSnackbar from "hooks/useSnackbar";
import { IOrderDetails } from "interfaces/Order";
import { IClinician } from "interfaces/Clinician";
import ClinicianService from "services/ClinicianService";
import { getPatientInfoLabel } from "helpers/PatientHelper";
import AppTypeSelection from "components/AppTypeSelection/AppTypeSelection";
import TestTypeSelection from "components/TestTypeSelection/TestTypeSelection";
import RangeDatePicker from "components/RangeDatePicker/RangeDatePicker";
import { OrderDirection, PageSize } from "enums/Common";
import { Interval, RollupInterval } from "enums/Test";
import { createUrlParams } from "helpers/CommonHelper";
import PatientLayoutFiltersModal from "components/Modals/PatientLayoutFiltersModal/PatientLayoutFiltersModal";
import useAvailableApps from "hooks/useAvailableApps";
import {
  formatTestNameByID,
  getAllTestTypes,
  getTestTypesThatSupportTrendsAndSummary,
} from "helpers/TestHelper";
import { IHistoryTest } from "interfaces/Test";
import TestService from "services/TestService";

const PatientViewLayout = () => {
  const { clinicianId, patientId, orderId, testId } = useParams();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { availableApps } = useAvailableApps();
  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const [clinician, setClinician] = useState<IClinician>();
  const [patient, setPatient] = useState<IPatient>();
  const [order, setOrder] = useState<IOrderDetails>();
  const [test, setTest] = useState<IHistoryTest>();
  const [loadingOrder, setLoadingOrder] = useState<boolean>(false);
  const [filters, setFilters] = useState<IPatientLayoutFilters>({
    page: 1,
    _total_count: 1,
    page_size: PageSize.Default,
    patientId,
    clinicianId,
    showTrends: searchParams.get("showTrends") !== "false",
    gaitInterval: Number(searchParams.get("gaitInterval") ?? Interval.OneMonth),
    balanceInterval: Number(
      searchParams.get("balanceInterval") ?? Interval.OneMonth
    ),
    apps:
      searchParams
        .get("apps")
        ?.split(",")
        .map((x) => Number(x)) ?? availableApps,
    tests:
      searchParams
        .get("tests")
        ?.split(",")
        .map((id) => Number(id)) ?? getAllTestTypes().map(({ value }) => value),
    accession: searchParams.get("accession") ?? undefined,
    createdOn: searchParams.get("createdOn") ?? undefined,
    rollup: Number(searchParams.get("rollup") ?? RollupInterval.Month),
    resultsOrderBy:
      searchParams.get("resultsOrderBy") ?? `created_on ${OrderDirection.Desc}`,
    ordersOrderBy:
      searchParams.get("ordersOrderBy") ?? `order_num ${OrderDirection.Desc}`,
  });
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState<boolean>(false); // mobile

  const patientBelongsToSameAccount = !Boolean(patient?.clientId);

  const isResultsPage = Boolean(
    matchPath(URLRoutes.PatientResults, pathname) ||
      matchPath(URLRoutes.ClinicianPatientResults, pathname)
  );
  const isTrendsPage = Boolean(
    matchPath(URLRoutes.PatientTrends, pathname) ||
      matchPath(URLRoutes.ClinicianPatientTrends, pathname)
  );

  const showFilters = isResultsPage || isTrendsPage;

  const testsThatSupportTrends = getTestTypesThatSupportTrendsAndSummary().map(
    ({ value }) => value
  );

  const patientInfoLabel = useMemo(
    (): string | undefined => getPatientInfoLabel(patient),
    [patient]
  );

  const breadcrumbsItems = useMemo(
    (): INavItem[] => [
      ...(clinician
        ? [
            {
              label: "Clinicians",
              to: URLRoutes.CliniciansDashboard,
            },
            {
              label: `${clinician.lastName}, ${clinician.firstName}`,
              to: generatePath(URLRoutes.ClinicianPreviewDashboard, {
                clinicianId: clinician.id,
              }),
            },
          ]
        : []),
      {
        label: "Patients",
        to: clinicianId
          ? generatePath(URLRoutes.ClinicianPatients, {
              clinicianId,
            })
          : URLRoutes.PatientsDashboard,
      },
      {
        label: patientInfoLabel || "",
        to: clinicianId
          ? generatePath(URLRoutes.ClinicianPatientDashboard, {
              clinicianId,
              patientId: patientId!,
            })
          : generatePath(URLRoutes.PatientDashboard, { patientId: patientId! }),
      },
      ...(order
        ? [
            {
              label: "Orders",
              to: clinicianId
                ? generatePath(URLRoutes.ClinicianPatientOrders, {
                    clinicianId,
                    patientId: patientId!,
                  })
                : generatePath(URLRoutes.PatientOrders, {
                    patientId: patientId!,
                  }),
            },
            {
              label: `#${order.orderNum}`,
              to: "#",
            },
          ]
        : []),
      ...(test
        ? [
            {
              label: "Results",
              to: clinicianId
                ? generatePath(URLRoutes.ClinicianPatientResults, {
                    clinicianId,
                    patientId: patientId!,
                  })
                : generatePath(URLRoutes.PatientResults, {
                    patientId: patientId!,
                  }),
            },
            {
              label: `Test Notes (${formatTestNameByID(test.testId)} - ${
                test.accession
              })`,
              to: "#",
            },
          ]
        : []),
    ],
    [patientInfoLabel, order, patientId, clinicianId, clinician, test]
  );

  const navbarItems = useMemo(
    (): INavItem[] =>
      [
        {
          to: clinicianId
            ? generatePath(URLRoutes.ClinicianPatientDashboard, {
                clinicianId,
                patientId: patientId!,
              })
            : generatePath(URLRoutes.PatientDashboard, {
                patientId: patientId!,
              }),
          label: "Dashboard",
        },
        {
          to: clinicianId
            ? generatePath(URLRoutes.ClinicianPatientResults, {
                clinicianId,
                patientId: patientId!,
              })
            : generatePath(URLRoutes.PatientResults, { patientId: patientId! }),
          label: "Results",
        },
        {
          to: clinicianId
            ? generatePath(URLRoutes.ClinicianPatientTrends, {
                clinicianId,
                patientId: patientId!,
              })
            : generatePath(URLRoutes.PatientTrends, { patientId: patientId! }),
          label: "Trends",
        },
        {
          to: clinicianId
            ? generatePath(URLRoutes.ClinicianPatientOrders, {
                clinicianId,
                patientId: patientId!,
              })
            : generatePath(URLRoutes.PatientOrders, { patientId: patientId! }),
          label: "RTM Orders",
        },
        {
          to: clinicianId
            ? generatePath(URLRoutes.ClinicianPatientProfile, {
                clinicianId,
                patientId: patientId!,
              })
            : generatePath(URLRoutes.PatientProfile, { patientId: patientId! }),
          label: "Patient Profile",
        },
      ].map((x) => ({
        ...x,
        onClick: () => setFilters((prev) => ({ ...prev, page: 1 })), // reset pagination when navigating
      })),
    [patientId, clinicianId]
  );

  useEffect(() => {
    if (!clinicianId) return;

    ClinicianService.getOne(clinicianId)
      .then(({ data }) => setClinician(data))
      .catch(() => setSnackbarError());
  }, [clinicianId, setSnackbarError]);

  useEffect(() => {
    if (!patientId) return;

    PatientService.getOne(patientId)
      .then(({ data }) => setPatient(data))
      .catch(() => setSnackbarError());
  }, [patientId, setSnackbarError]);

  const getOrder = useCallback(() => {
    if (!orderId) return;

    setLoadingOrder(true);

    OrderService.getOne({ orderId })
      .then(({ data }) => setOrder(data))
      .catch(() => setSnackbarError())
      .finally(() => setLoadingOrder(false));
  }, [orderId, setSnackbarError]);

  useEffect(() => {
    if (orderId) {
      getOrder();
    } else {
      setOrder(undefined);
    }
  }, [orderId, getOrder]);

  useEffect(() => {
    if (testId) {
      TestService.getTestHistory({ id: testId })
        .then(({ data }) => {
          const foundTest = data.find((x) => x.id === testId);

          if (!foundTest) {
            throw new Error();
          } else {
            setTest(foundTest);
          }
        })
        .catch(() => setSnackbarError());
    } else {
      setTest(undefined);
    }
  }, [testId, setSnackbarError]);

  useEffect(() => {
    const {
      page,
      patientId,
      _total_count,
      page_size,

      // push only props that can be filtered
      ...urlParams
    } = filters;

    setSearchParams(createUrlParams(urlParams), { replace: true });
  }, [filters, setSearchParams]);

  return (
    <div className="h-full flex flex-col gap-4">
      <Breadcrumbs items={breadcrumbsItems} />

      {/* NavBar is hidden on Order Details and Test Notes pages */}
      {/* Additionaly hide it if patient doesn't belong to same account as logegd in user (global admin has access only to profile page) */}
      {!matchPath(URLRoutes.PatientOrderDetails, pathname) &&
        !matchPath(URLRoutes.ClinicianPatientOrderDetails, pathname) &&
        !matchPath(URLRoutes.PatientTestNotes, pathname) &&
        !matchPath(URLRoutes.ClinicianPatientTestNotes, pathname) &&
        patientBelongsToSameAccount && (
          <Navbar
            preserveQueryParams // preserve filters, since they are shared across layout
            items={navbarItems}
            drawerTitle="Patient"
            drawerLabel={patientInfoLabel}
          />
        )}

      <div className="relative grow h-0 flex gap-5">
        {showFilters && (
          <>
            <div className="hidden w-64 xl:flex xl:overflow-auto flex-col gap-3">
              <AppTypeSelection
                selected={filters.apps}
                onChange={(apps) =>
                  setFilters((prev) => ({ ...prev, page: 1, apps }))
                }
              />

              <TestTypeSelection
                showOnlyTestsThatSupportTrends={isTrendsPage}
                selected={
                  isTrendsPage
                    ? filters.tests.filter((x) =>
                        testsThatSupportTrends.includes(x)
                      ) // on trends page show only tests that support trends
                    : filters.tests
                }
                onChange={(tests) =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    tests,
                    resultsOrderBy: `created_on ${OrderDirection.Desc}`,
                  }))
                }
              />

              {isResultsPage && (
                <div
                  className={
                    "py-2.5 text-center text-sm font-medium rounded-md border-solid border border-pelorous bg-pattensBlue text-cloudBurst"
                  }
                >
                  Data displayed is limited when multiple test types are
                  selected.
                </div>
              )}

              <RangeDatePicker
                value={filters.createdOn}
                onChange={(value) =>
                  setFilters((prev) => ({ ...prev, page: 1, createdOn: value }))
                }
              />
            </div>

            <button
              className="z-10 absolute top-0 right-5 flex justify-center items-center w-10 h-10 rounded-full border-2 border-pelorous bg-white xl:hidden"
              onClick={() => setIsFiltersModalOpen(true)}
            >
              <span className="icon_svg icon_filter" />
            </button>
          </>
        )}

        <div className="grow w-0">
          <Outlet
            context={{
              patient,
              setPatient,
              order,
              filters,
              setFilters,
              loadingOrder,
              refreshOrder: getOrder,
              clinician,
            }}
          />
        </div>
      </div>

      {isFiltersModalOpen && (
        <PatientLayoutFiltersModal
          appliedFilters={filters}
          onApply={(value) =>
            setFilters((prev) => ({ ...prev, ...value, page: 1 }))
          }
          onClose={() => setIsFiltersModalOpen(false)}
        />
      )}

      {snackbarComponent}
    </div>
  );
};

export default PatientViewLayout;

export const usePatientViewLayoutContext = () => {
  return useOutletContext<{
    patient: IPatient | undefined;
    setPatient: Dispatch<SetStateAction<IPatient | undefined>>;
    clinician: IClinician | undefined;
    order: IOrderDetails | undefined;
    filters: IPatientLayoutFilters;
    setFilters: Dispatch<SetStateAction<IPatientLayoutFilters>>;
    loadingOrder: boolean;
    refreshOrder: () => void;
  }>();
};
