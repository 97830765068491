import { useCallback, useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { AxiosResponse } from "axios";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ButtonCloseModal from "components/Buttons/ButtonCloseModal/ButtonCloseModal";
import { MimeType, Scale as ScaleEnum, SlideTimeout } from "enums/Common";
import Loading from "components/Loading/Loading";
import { downloadFile } from "helpers/CommonHelper";
import { mergePDFs } from "helpers/PDFHelper";

interface ReportsModalProps {
  fileName?: string;
  reportPromises: Promise<AxiosResponse>[];
  onClose: () => void;
  onError: () => void;
}

const ReportsModal = (props: ReportsModalProps) => {
  const {
    fileName = "custom-reports.pdf",
    reportPromises,
    onClose,
    onError,
  } = props;

  const documentRef = useRef<HTMLDivElement | null>(null);

  const [pdfFile, setPdfFile] = useState<
    { url: string; fileName: string } | undefined
  >(undefined);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
  const [scale, setScale] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [noReportData, setNoReportData] = useState<boolean | undefined>(
    undefined
  );

  // we set this flag when user uses controls to change page so we can return from onScroll callback
  const isScrollActiveRef = useRef<boolean>(false);

  useEffect(() => {
    setLoading(true);

    // multiple pdf documents must be merged into one document

    Promise.all(reportPromises)
      .then(
        (responses) =>
          new Promise<Blob>((resolve, reject) => {
            // no need to merge pdfs if only one request is provided
            if (responses.length === 1) {
              if (responses[0].status === 204) {
                setNoReportData(true);
                setLoading(false);
                return;
              }
              const { request } = responses[0];
              const blob = request.response;

              resolve(blob);
            } else {
              responses = responses.filter((item) => item.status !== 204);
              if (responses.length === 0) {
                setNoReportData(true);
                setLoading(false);
                return;
              }

              const blobs: Blob[] = responses.map(
                ({ request }) => request.response
              );

              mergePDFs(blobs)
                .then((blob) => resolve(blob))
                .catch(() => reject());
            }
          })
      )
      .then((blob) => {
        const file = new File([blob], fileName, {
          type: MimeType.PDF,
        });
        const url = URL.createObjectURL(file);

        setPdfFile({
          url,
          fileName,
        });
      })
      .catch(() => {
        onClose();
        onError();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fileName, reportPromises, onError, onClose]);

  const handlePrint = useCallback(() => {
    const iframe = document.createElement("iframe");
    document.body.appendChild(iframe);

    iframe.style.display = "none";

    iframe.src = pdfFile!.url;

    iframe.onload = () => {
      iframe.focus();
      iframe.contentWindow?.print();
    };
  }, [pdfFile]);

  const handlePageChange = useCallback(
    (isPageUp: boolean) => {
      if (!documentRef.current || !totalPages) return;

      const nextPage = isPageUp ? page + 1 : page - 1;

      const pageHeight = Math.floor(
        documentRef.current.scrollHeight / totalPages
      );

      isScrollActiveRef.current = true;
      documentRef.current.scroll({
        top: pageHeight * (nextPage - 1),
        behavior: "smooth",
      });

      setPage(nextPage);
    },
    [page, totalPages]
  );

  const handleScroll = useCallback(() => {
    if (!documentRef.current || !totalPages) return;

    const { scrollHeight, scrollTop } = documentRef.current;
    const pageHeight = Math.floor(scrollHeight / totalPages);
    const currentPage = Math.floor(scrollTop / pageHeight) + 1;

    // don't set page if scrolling with controls is active
    if (!isScrollActiveRef.current) {
      setPage(currentPage);
    }

    // reset flag when scrolling is finished
    if (
      currentPage === page &&
      Math.floor(scrollTop) === pageHeight * (page - 1)
    ) {
      isScrollActiveRef.current = false;
    }
  }, [totalPages, page]);

  return (
    <Modal open onClose={onClose}>
      <Slide in direction="left" timeout={SlideTimeout.Default}>
        <div className="flex flex-col overflow-auto bg-aliceBlue border border-cloudBurst rounded-t-md absolute top-3 right-0 bottom-0 left-0 lg:landscape:left-auto lg:landscape:w-[636px] xl:left-auto xl:w-[636px]">
          <ButtonCloseModal className="!top-5 left-4" onClick={onClose} />

          <div className="flex justify-between items-center gap-3 border-b border-b-prussianBlue-100 pt-4 pb-2 pl-16 pr-6">
            <div className="flex items-center gap-3">
              <div className="flex gap-3 md:gap-8">
                <button
                  className="disabled:opacity-50"
                  disabled={!pdfFile || page === 1}
                  onClick={() => handlePageChange(false)}
                >
                  <span className="icon_svg icon_navigate_arrow" />
                </button>
                <button
                  className="disabled:opacity-50"
                  disabled={!pdfFile || page === totalPages}
                  onClick={() => handlePageChange(true)}
                >
                  <span className="icon_svg icon_navigate_arrow rotate-180" />
                </button>
              </div>

              <span
                className={`text-sm text-cloudBurst w-16 whitespace-nowrap ${
                  totalPages ? "" : "opacity-0"
                }`}
              >
                Page {page} of {totalPages}
              </span>
            </div>

            <div className="flex items-center gap-5">
              <button
                className="flex disabled:opacity-50"
                disabled={!pdfFile}
                onClick={handlePrint}
              >
                <span className="icon_svg icon_print" />
              </button>
              <button
                className="flex disabled:opacity-50"
                disabled={!pdfFile}
                onClick={() => downloadFile(pdfFile!.url, pdfFile!.fileName)}
              >
                <span className="icon_svg icon_download" />
              </button>
            </div>

            <div className="flex items-center gap-2">
              <button
                className="flex justify-center items-center w-[18px] h-[18px] bg-pelorous disabled:opacity-50"
                disabled={!pdfFile}
                onClick={() => setScale((prev) => prev + ScaleEnum.Step)}
              >
                <span className="icon_svg icon_plus" />
              </button>
              <button
                className="flex justify-center items-center w-[18px] h-[18px] bg-pelorous disabled:opacity-50"
                disabled={!pdfFile || scale <= ScaleEnum.Min}
                onClick={() => setScale((prev) => prev - ScaleEnum.Step)}
              >
                <span className="icon_svg icon_minus" />
              </button>
              <span className="text-sm text-cloudBurst w-20">
                Zoom {(scale * 100).toFixed(0)}%
              </span>
            </div>
          </div>

          <div
            ref={documentRef}
            className="relative overflow-auto grow p-3"
            onScroll={handleScroll}
          >
            {noReportData ? (
              <div className="flex justify-center items-center h-screen">
                <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                  <div className="mb-4">
                    <ContentPasteIcon style={{ fontSize: "35px" }} />
                  </div>
                  <h2 className="text-2xl font-bold mb-2">No Results</h2>
                  <p className="text-base mb-4">
                    No tests were found that meet the selected criteria.
                    <br />
                    Alter the criteria and try again.
                  </p>
                  <button
                    onClick={onClose}
                    className="bg-pelorous text-white rounded-full py-2 px-4 w-28 focus:outline-none focus:shadow-outline-blue"
                  >
                    OK
                  </button>
                </div>
              </div>
            ) : (
              <Document
                className="mx-auto w-fit"
                file={pdfFile?.url}
                onLoadSuccess={({ numPages }) => setTotalPages(numPages)}
                loading=""
                noData=""
              >
                {Array.from(new Array(totalPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    scale={scale}
                    loading=""
                  />
                ))}
              </Document>
            )}

            <Loading loading={loading} />
          </div>
        </div>
      </Slide>
    </Modal>
  );
};

export default ReportsModal;
