import { useCallback, useEffect, useState } from "react";
import { IToggleButton } from "../../interfaces/Common";

interface ToggleButtonsProps {
  buttons: IToggleButton[];
  initialActiveIndex: number;
  /* 
    use this prop if toggle state needs to be changed from outside of this component
    it will triger useEffect when changed
   */
  active?: number;
}

export const ToggleButtons = (props: ToggleButtonsProps) => {
  const { buttons, initialActiveIndex, active } = props;

  const [activeIndex, setActiveIndex] = useState<number>(initialActiveIndex);

  useEffect(() => {
    if (!active) return;

    setActiveIndex(active);
  }, [active]);

  const handleButtonClick = useCallback(
    (index: number, onClick: () => void) => {
      setActiveIndex(index);
      onClick();
    },
    []
  );

  return (
    <div className="w-full h-[42px] flex bg-white rounded-[50px] sm:w-fit">
      {buttons.map(({ label, onClick, disabled }, index) => (
        <button
          key={label}
          className={`w-1/2 sm:w-40 border-2 border-transparent rounded-[50px] flex justify-center items-center text-cloudBurst text-lg leading-5 font-bold ${
            index === activeIndex
              ? "bg-pelorous !border-pattensBlue !text-white"
              : ""
          } ${disabled ? "opacity-50" : ""}`}
          disabled={index === activeIndex || disabled}
          onClick={() => handleButtonClick(index, onClick)}
        >
          {label}
        </button>
      ))}
    </div>
  );
};
