import { useEffect, useState } from "react";
import axios from "axios";
import AccountAnalysis from "components/AccountAnalysis/AccountAnalysis";
import PageTitleDescription from "components/PageTitleDescription/PageTitleDescription";
import RTMAnalysis from "components/RTMAnalysis/RTMAnalysis";
import RTMStatistics from "components/RTMStatistics/RTMStatistics";
import UsageTotals from "components/UsageTotals/UsageTotals";
import {
  IAccountStatisticsFilters,
  IKPIAccount,
  IKPIAccountData,
} from "interfaces/KPI";
import KPIService from "services/KPIService";
import useSnackbar from "hooks/useSnackbar";
import Loading from "components/Loading/Loading";
import { OrderDirection, PageSize } from "enums/Common";

const KPIDashboard = () => {
  const { setSnackbarError, snackbarComponent } = useSnackbar();

  const [accountData, setAccountData] = useState<IKPIAccountData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedAccount, setSelectedAccount] = useState<IKPIAccount | null>(
    null
  );
  const [statisticsFilters, setStatisticsFilters] =
    useState<IAccountStatisticsFilters>({
      _total_count: 1,
      page_size: PageSize.Default,
      page: 1,
      _order_by: `patients ${OrderDirection.Desc}`,
    });

  useEffect(() => {
    const controller = new AbortController();

    KPIService.getAccountData(controller.signal)
      .then(({ data }) => {
        setAccountData(data);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;

        setSnackbarError();
        setLoading(false);
      });

    return () => controller.abort();
  }, [setSnackbarError]);

  const handleAccountChange = (value: IKPIAccount | null) => {
    setSelectedAccount(value);
    setStatisticsFilters((prev) => ({ ...prev, page: 1, id: value?.id }));
  };

  return (
    <div className="h-full flex flex-col gap-4 pr-5 xl:pr-0">
      <PageTitleDescription title="Key Performance Indicators" />

      <div className="relative grow h-0 pb-5 overflow-auto flex flex-col gap-9">
        {accountData && (
          <>
            <div className="flex flex-col gap-3">
              <AccountAnalysis data={accountData.overview} />
              <UsageTotals data={accountData.details} />
            </div>
            <div className="flex flex-col gap-3">
              <RTMAnalysis
                selectedAccount={selectedAccount}
                onAccountChange={handleAccountChange}
                onFetchFail={setSnackbarError}
              />
              <RTMStatistics
                filters={statisticsFilters}
                setFilters={setStatisticsFilters}
                onFetchFail={setSnackbarError}
              />
            </div>
          </>
        )}

        <Loading loading={loading} />
      </div>

      {snackbarComponent}
    </div>
  );
};

export default KPIDashboard;
