import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import useSnackbar from "hooks/useSnackbar";
import useFetchTableData from "hooks/useFetchTableData";
import { ITableColumn } from "interfaces/Common";
import {
  createUrlParams,
  formatDashDate,
  formatName,
} from "helpers/CommonHelper";
import Loading from "components/Loading/Loading";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import { IOrderFilters, IBaseOrder } from "interfaces/Order";
import OrderService from "services/OrderService";
import tableStyles from "styles/shared/table.module.scss";

interface NewOrdersTableProps {
  filters: IOrderFilters;
  setFilters: Dispatch<SetStateAction<IOrderFilters>>;
  onAssignSensor: (value: IBaseOrder) => void;
}

const tableColumns: ITableColumn[] = [
  { label: "Patient", sortProperty: "patient_lname" },
  { label: "Account", sortProperty: "client" },
  { label: "Order start date", sortProperty: "start_date" },
  { label: "" },
];

const NewOrdersTable = ({
  filters,
  setFilters,
  onAssignSensor,
}: NewOrdersTableProps) => {
  const [, setSearchParams] = useSearchParams();

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  const { snackbarComponent, setSnackbarError } = useSnackbar();
  const { data, loading, scrollableParentRef, lastElementRef } =
    useFetchTableData<IBaseOrder, IOrderFilters>({
      filters,
      onPageIncrement,
      fetchAPI: OrderService.getNew,
      onError: setSnackbarError,
    });

  useEffect(() => {
    const {
      page,
      page_size,
      _total_count,
      // push only props that can be filtered
      ...urlParams
    } = filters;

    setSearchParams(createUrlParams(urlParams), { replace: true });
  }, [filters, setSearchParams]);

  return (
    <>
      <div className="h-full relative">
        <TableContainer
          className={`${tableStyles.shared_table_container} ${tableStyles.padding_16_20}`}
          ref={scrollableParentRef}
        >
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map(({ label, sortProperty }, index) => (
                  <TableCell key={index}>
                    {sortProperty ? (
                      <TableSortLabel
                        label={label}
                        sortProperty={sortProperty}
                        orderBy={filters._order_by}
                        onSort={(value) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            _order_by: value,
                          }))
                        }
                      />
                    ) : (
                      label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((order, index) => (
                <TableRow
                  key={order.id}
                  ref={index === data.length - 1 ? lastElementRef : null}
                >
                  <TableCell>
                    {formatName({
                      firstName: order.patientFname,
                      lastName: order.patientLname,
                      middleName: order.patientMname,
                    })}
                  </TableCell>
                  <TableCell>{order.client}</TableCell>
                  <TableCell>{formatDashDate(order.startDate)}</TableCell>
                  <TableCell className="w-32">
                    <button
                      className="text-pelorous hover:underline"
                      onClick={() => onAssignSensor(order)}
                    >
                      Assign Sensor
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {!loading && !data.length && (
          <div className="mt-7">
            <NoTableItems
              icon={
                filters["patient_lname|patient_fname"]
                  ? "icon_search"
                  : "icon_no_results"
              }
              message={"No pending orders"}
              search={filters["patient_lname|patient_fname"]}
            />
          </div>
        )}

        <Loading loading={loading} />
      </div>
      {snackbarComponent}
    </>
  );
};

export default NewOrdersTable;
