import { EChartsOption } from "echarts";
import RTMAnalysisChartWrapper from "components/RTMAnalysis/RTMAnalysisChartWrapper/RTMAnalysisChartWrapper";
import { Color } from "enums/Common";
import {
  get12MonthsDataZoomParams,
  getMonthAndYearXValues,
  sortMonthAndYearData,
} from "helpers/KPIChartHelper";
import { IRTMAnalysisData } from "interfaces/KPI";

interface RTMOrdersByMonthChartProps {
  data: IRTMAnalysisData["monthlyOrders"];
}

const RTMOrdersByMonthChart = ({ data }: RTMOrdersByMonthChartProps) => {
  const sortedData = sortMonthAndYearData({
    data,
    monthKey: "month",
    yearKey: "year",
  });

  const xValues = getMonthAndYearXValues({
    data: sortedData,
    monthKey: "month",
    yearKey: "year",
  });
  const yValues = sortedData.map(({ count }) => count);

  const getOptions = (): EChartsOption => {
    const dataZoom = get12MonthsDataZoomParams({ data, bottom: 5 });

    return {
      tooltip: {
        trigger: "item",
        formatter: "{b}: {c}", // name: value
      },
      grid: {
        top: 5,
        bottom: dataZoom ? 15 : 0,
        left: 5,
        right: 0,
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 12,
            lineHeight: 15,
            fontFamily: "Cabin",
            color: Color.Nero,
            rotate: 90,
          },
          data: xValues,
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            fontSize: 10,
            lineHeight: 13,
            fontFamily: "Cabin",
            color: Color.Nero,
          },
          splitLine: {
            lineStyle: { color: Color.VeryLightGrey },
          },
          axisTick: { show: true },
        },
      ],
      series: [
        {
          barWidth: 6,
          type: "bar",
          color: Color.Endeavour,
          emphasis: {
            focus: "series",
          },
          data: yValues,
        },
      ],
      legend: {
        icon: "square",
        itemWidth: 12,
        bottom: -8,
        itemGap: 42,
        textStyle: {
          fontSize: 12,
          lineHeight: 15,
          fontFamily: "Cabin",
          color: Color.Nero,
        },
      },
      dataZoom,
      minInterval: 1,
    };
  };

  return (
    <RTMAnalysisChartWrapper
      key={data.length}
      isEmpty={!data.length}
      title="RTM Orders by Month"
      chartOptions={getOptions()}
    />
  );
};

export default RTMOrdersByMonthChart;
