import PDFMerger from "pdf-merger-js/browser";

// merges multiple pdf data into single pdf
export const mergePDFs = (pdfs: Blob[]): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const pdfMerger = new PDFMerger();
    const pdfMergePromises: Promise<void>[] = [];

    for (const blob of pdfs) {
      pdfMergePromises.push(pdfMerger.add(blob));
    }

    Promise.all(pdfMergePromises)
      .then(() => pdfMerger.saveAsBlob())
      .then((blob) => resolve(blob))
      .catch(() => reject());
  });
};
