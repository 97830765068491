import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  LocalStorage,
  OrderDirection,
  PageSize,
  ValidationLoginError,
} from "enums/Common";
import useSnackbar from "hooks/useSnackbar";
import PageTitleDescription from "components/PageTitleDescription/PageTitleDescription";
import DebouncedSearchField from "components/DebouncedSearchField/DebouncedSearchField";
import Button from "components/Buttons/Button/Button";
import CreateAccountModal from "components/Modals/CreateAccountModal/CreateAccountModal";
import AccountsTable from "components/Tables/AccountsTable/AccountsTable";
import { IAccountDirectory, IAccountFilters } from "interfaces/Account";
import AuthService from "services/AuthService";
import ConfirmPasswordModal from "components/Account/ConfirmPasswordModal/ConfirmPasswordModal";
import { IAuth, IAuthInitialData } from "interfaces/Auth";
import LocalStorageService from "services/LocalStorageService";
import { Auth, AccountAuth } from "recoil-states/auth-states";
import UserService from "services/UserService";
import StatusDropdown from "components/Dropdowns/StatusDropdown/StatusDropdown";
import { getIsInactiveFromSearchParams } from "helpers/CommonHelper";

const Accounts = () => {
  const [searchParams] = useSearchParams();

  const { snackbarComponent, setSnackbarSuccess, setSnackbarError } =
    useSnackbar();

  const [filters, setFilters] = useState<IAccountFilters>({
    _total_count: 1,
    page_size: PageSize.Default,
    page: 1,
    _order_by: searchParams.get("_order_by") || `name ${OrderDirection.Asc}`,
    withInactive: true,
    name: searchParams.get("name") || undefined,
    isInactive: getIsInactiveFromSearchParams(searchParams),
  });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isSwitchModalOpen, setIsSwitchModalOpen] = useState<boolean>(false);
  const [isDataInvalid, setIsDataInvalid] = useState<
    ValidationLoginError | undefined
  >(undefined);
  const [selectedAccount, setSelectedAccount] = useState<
    IAccountDirectory | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const auth = useRecoilValue(Auth);
  const [accountAuth, setAccountAuth] = useRecoilState(AccountAuth);

  const handleSwitchAccount = (account: IAccountDirectory) => {
    setIsSwitchModalOpen(true);
    setSelectedAccount(account);
  };

  const handleCreateEditAccount = (account: IAccountDirectory) => {
    setSelectedAccount(account);
    setIsCreateModalOpen(true);
  };

  const validationLoginErrorMessage = useMemo(() => {
    switch (isDataInvalid) {
      case ValidationLoginError.Invalid:
        return "Your password is incorrect.";
      case ValidationLoginError.Forbidden:
        return "Website access granted to Clinicians only.";
      default:
        return "";
    }
  }, [isDataInvalid]);

  const handlePasswordConfirmation = (password?: string) => {
    if (!password || !auth?.initialData || !selectedAccount) return;
    setIsDataInvalid(undefined);
    setLoading(true);

    if (accountAuth) {
      // Logout account if exists, before switching to another acoount
      AuthService.logout().then(() => {
        LocalStorageService.removeAccountAuth();
        setAccountAuth(undefined);
      });
    }

    const credentials = {
      email: auth.initialData.user.email,
      password: password,
    };

    AuthService.login(credentials, { clientId: selectedAccount.id })
      .then(
        ({ data: authData }: { data: Omit<IAuth, "initialData"> }) => authData
      )
      .then(
        (authData) =>
          // send token with this API, since at this point local storage is empty and token will not be attached with interceptor
          // after that we can combine login and initial-data in one state
          new Promise<{
            authData: IAuth;
            initialData: IAuthInitialData;
          }>((resolve, reject) => {
            UserService.getUserInitialData(authData.token)
              .then(({ data: initialData }: { data: IAuthInitialData }) =>
                resolve({ authData, initialData })
              )
              .catch((e) => reject(e));
          })
      )
      .then(({ authData, initialData }) => {
        const auth: IAuth = { ...authData, initialData };
        LocalStorageService.setAuth(auth, LocalStorage.AccountAuth);
        setAccountAuth(auth);
        setIsSwitchModalOpen(false);
      })
      .catch((e) => {
        setIsDataInvalid(
          e.response.data.code === 101
            ? ValidationLoginError.Forbidden
            : ValidationLoginError.Invalid
        );
      })
      .finally(() => setLoading(false));
  };

  const closeSwitchModal = () => {
    setIsSwitchModalOpen(false);
    setSelectedAccount(undefined);
    setIsDataInvalid(undefined);
  };

  return (
    <div className="h-full flex flex-col gap-5">
      <div className="flex flex-col gap-4 pr-5 xl:pr-0">
        <div className="flex flex-col gap-4 justify-between sm:flex-row">
          <PageTitleDescription
            className="w-full xl:w-fit"
            title="Accounts"
            description="Manage existing accounts or create new ones."
          />

          <Button
            className="shrink-0 self-center"
            onClick={() => setIsCreateModalOpen(true)}
          >
            Create New Account
          </Button>
        </div>

        <div className="flex flex-col justify-between items-stretch gap-4 sm:items-start md:flex-row lg:items-center">
          <DebouncedSearchField
            placeholder="Search by Account Name"
            value={filters["name"] ?? undefined}
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, page: 1, name: value }))
            }
          />
          <StatusDropdown
            status={filters.isInactive}
            onChange={(isInactive) =>
              setFilters((prev) => ({ ...prev, page: 1, isInactive }))
            }
          />
        </div>
      </div>

      <div className="grow h-0">
        <AccountsTable
          filters={filters}
          setFilters={setFilters}
          handleSelectAccount={handleCreateEditAccount}
          handleSwitchAccount={handleSwitchAccount}
        />
      </div>

      {isCreateModalOpen && (
        <CreateAccountModal
          initialData={selectedAccount}
          onClose={() => {
            setIsCreateModalOpen(false);
            setSelectedAccount(undefined);
          }}
          onConfirm={() => {
            setIsCreateModalOpen(false);
            setSelectedAccount(undefined);
            setFilters((prev) => ({ ...prev, page: 1 }));
          }}
          onSuccess={setSnackbarSuccess}
          onError={setSnackbarError}
        />
      )}

      {isSwitchModalOpen && (
        <ConfirmPasswordModal
          onClose={closeSwitchModal}
          onConfirm={handlePasswordConfirmation}
          loading={loading}
          error={validationLoginErrorMessage}
        />
      )}

      {snackbarComponent}
    </div>
  );
};

export default Accounts;
