import { useRef } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import CircleIcon from "@mui/icons-material/Circle";
import { MenuOption } from "enums/Common";
import { IDropdownOption } from "interfaces/Common";
import styles from "./Dropdown.module.scss";

interface DropdownProps {
  useInputFieldStyle?: boolean;
  hideClearButton?: boolean;
  label: string;
  selected?: number;
  disabled?: boolean;
  placeholder?: string;
  error?: boolean;
  options: IDropdownOption<number>[];
  onChange: (value: number | undefined) => void;
  showRadioButton?: boolean;
}

const Dropdown = (props: DropdownProps) => {
  const {
    useInputFieldStyle,
    hideClearButton,
    label,
    selected,
    disabled,
    placeholder = "Select",
    error,
    options,
    onChange,
    showRadioButton,
  } = props;

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={wrapperRef}
      className={`flex flex-col gap-1 ${
        useInputFieldStyle
          ? styles.input_field_wrapper
          : styles.dropdown_wrapper
      }`}
    >
      <div className="flex items-end justify-between">
        <label className="text-base leading-5 text-cloudBurst font-medium">
          {label}
        </label>
        {!hideClearButton && (
          <button
            className="text-sm leading-4 text-pelorous disabled:opacity-50 font-medium"
            disabled={selected === undefined}
            onClick={() => onChange(undefined)}
          >
            Clear
          </button>
        )}
      </div>
      <Select
        error={error}
        displayEmpty
        disabled={disabled}
        MenuProps={{
          classes: { root: styles.select_root },
          PaperProps: { style: { width: wrapperRef.current?.clientWidth } },
        }}
        value={selected !== undefined ? selected : MenuOption.Unselected}
        onChange={({ target: { value } }) => onChange(Number(value))}
        renderValue={(selected) =>
          selected !== MenuOption.Unselected
            ? options.find(({ value }) => value === selected)?.label
            : placeholder
        }
      >
        <MenuItem disabled className="!hidden" value={MenuOption.Unselected}>
          Select
        </MenuItem>
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {showRadioButton && (
              <Radio
                disableRipple
                icon={<CircleTwoToneIcon />}
                checkedIcon={<CircleIcon />}
                checked={selected === value}
              />
            )}
            {`${label}`}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default Dropdown;
