import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import kpiTableStyles from "styles/shared/kpiTable.module.scss";
import { IKPIAccountData } from "interfaces/KPI";
import { ITableColumn } from "interfaces/Common";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import { useState } from "react";
import { OrderDirection } from "enums/Common";
import { sortItemsLocally } from "helpers/CommonHelper";

interface UsageTotalsProps {
  data: IKPIAccountData["details"];
}

interface IProductData {
  name: string;
  activeAccounts: number;
  inactiveAccounts: number;
  totalPatients: number;
  monthOrdered: number;
  // Lab At Home and Remove have only Balance and Gait tests, others will be shown as "N/A" in table (API returns 0 count for them, but we will set it to undefined on client side)
  gaitCount: number;
  balanceCount: number;
  symmetryCount?: number;
  anglesCount?: number;
  tremorCount?: number;
  cognitiveCount?: number;
}

const sumProductData = (products: IProductData[]): IProductData => {
  return products.reduce((acc, product) => {
    // Iterating over each property except 'name'
    (Object.keys(product) as (keyof IProductData)[]).forEach((key) => {
      if (key !== "name") {
        acc[key] = (acc[key] ?? 0) + (product[key] ?? 0);
      } else {
        acc[key] = "Total"; // Assign a generic name to the result
      }
    });
    return acc;
  });
};

const topHeaderCells: { label: string; colSpan?: number }[] = [
  { label: "" },
  { label: "Accounts", colSpan: 2 },
  { label: "" },
  { label: "" },
  { label: "RTM Orders" },
  { label: "# Tests Performed", colSpan: 6 },
];

const bottomHeaderCells: ITableColumn[] = [
  { label: "Product", sortProperty: "name" },
  { label: "Active", sortProperty: "activeAccounts" },
  { label: "Inactive", sortProperty: "inactiveAccounts" },
  { label: "Ordering\nClinicians" },
  { label: "Total\nPatients", sortProperty: "totalPatients" },
  { label: "Billable Months\nOrdered", sortProperty: "monthOrdered" },
  { label: "Gait", sortProperty: "gaitCount" },
  { label: "Balance", sortProperty: "balanceCount" },
  { label: "Symmetry", sortProperty: "symmetryCount" },
  { label: "Angles", sortProperty: "anglesCount" },
  { label: "Tremor", sortProperty: "tremorCount" },
  { label: "Cognitive", sortProperty: "cognitiveCount" },
];

const UsageTotals = ({ data }: UsageTotalsProps) => {
  const { clinicians, accounts, patients, monthOrdered, testCount } = data;

  const [orderBy, setOrderBy] = useState<string>(
    `totalPatients ${OrderDirection.Desc}`
  );

  const labAtHomeData: IProductData = {
    name: "Lab At Home",
    activeAccounts: accounts[0].ah,
    inactiveAccounts: accounts[0].ahInactive,
    totalPatients: patients.ah,
    monthOrdered: monthOrdered.ah,
    balanceCount: testCount.ahBalance,
    gaitCount: testCount.ahGait,
  };

  const inClinicData: IProductData = {
    name: "In Clinic",
    activeAccounts: accounts[0].lab,
    inactiveAccounts: accounts[0].labInactive,
    totalPatients: patients.lab,
    monthOrdered: monthOrdered.lab,
    balanceCount: testCount.labBalance,
    gaitCount: testCount.labGait,
    anglesCount: testCount.labAngles,
    cognitiveCount: testCount.labCognitive,
    symmetryCount: testCount.labSymmetry,
    tremorCount: testCount.labTremor,
  };

  const remoteData: IProductData = {
    name: "Remote",
    activeAccounts: accounts[0].rt,
    inactiveAccounts: accounts[0].rtInactive,
    totalPatients: patients.rt,
    monthOrdered: monthOrdered.rt,
    balanceCount: testCount.rtBalance,
    gaitCount: testCount.rtGait,
  };

  const sortedItems = sortItemsLocally(
    [{ ...labAtHomeData }, { ...remoteData }, { ...inClinicData }],
    orderBy
  );

  const totalData = sumProductData([
    { ...labAtHomeData },
    { ...remoteData },
    { ...inClinicData },
  ]);

  return (
    <div className="p-6 pt-3 bg-white rounded-[9px]">
      <p className="mb-2 text-cloudBurst text-sm leading-4 font-medium">
        Usage Totals
      </p>
      <TableContainer
        className={`${kpiTableStyles.kpi_table_container} ${kpiTableStyles.last_row_total}`}
      >
        <Table>
          <TableHead>
            <TableRow>
              {topHeaderCells.map(({ label, colSpan }, index) => (
                <TableCell key={index} colSpan={colSpan}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {bottomHeaderCells.map(({ label, sortProperty }) => (
                <TableCell key={label}>
                  {sortProperty ? (
                    <TableSortLabel
                      whiteTheme
                      label={label}
                      sortProperty={sortProperty}
                      orderBy={orderBy}
                      onSort={setOrderBy}
                    />
                  ) : (
                    label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...sortedItems, totalData].map(
              (
                {
                  name,
                  activeAccounts,
                  inactiveAccounts,
                  totalPatients,
                  monthOrdered,
                  gaitCount,
                  balanceCount,
                  symmetryCount,
                  anglesCount,
                  tremorCount,
                  cognitiveCount,
                },
                index
              ) => (
                <TableRow key={index}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{activeAccounts.toLocaleString()}</TableCell>
                  <TableCell>{inactiveAccounts.toLocaleString()}</TableCell>
                  {index === 0 && (
                    <TableCell rowSpan={4}>
                      {clinicians.toLocaleString()}
                    </TableCell>
                  )}
                  <TableCell>{totalPatients.toLocaleString()}</TableCell>
                  <TableCell>{monthOrdered.toLocaleString()}</TableCell>
                  <TableCell>{gaitCount.toLocaleString()}</TableCell>
                  <TableCell>{balanceCount.toLocaleString()}</TableCell>
                  <TableCell>
                    {symmetryCount?.toLocaleString() ?? "N/A"}
                  </TableCell>
                  <TableCell>
                    {anglesCount?.toLocaleString() ?? "N/A"}
                  </TableCell>
                  <TableCell>
                    {tremorCount?.toLocaleString() ?? "N/A"}
                  </TableCell>
                  <TableCell>
                    {cognitiveCount?.toLocaleString() ?? "N/A"}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UsageTotals;
