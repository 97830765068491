import React from "react";

interface AccountAnalysisCountProps {
  count: number;
  label: string;
}

const AccountAnalysisCount = ({ count, label }: AccountAnalysisCountProps) => {
  return (
    <div className="flex items-center gap-3">
      <span className="text-endeavour text-4xl leading-[44px] font-bold">
        {count.toLocaleString()}
      </span>
      <span className="text-cloudBurst text-sm leading-4 font-medium">
        {label}
      </span>
    </div>
  );
};

export default AccountAnalysisCount;
