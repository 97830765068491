import { EChartsOption } from "echarts";
import ReactECharts from "echarts-for-react";
import { Color } from "enums/Common";

interface RTMAnalysisChartWrapperProps {
  isEmpty: boolean;
  title: string;
  chartOptions: EChartsOption;
}

const RTMAnalysisChartWrapper = (props: RTMAnalysisChartWrapperProps) => {
  const { isEmpty, title, chartOptions } = props;

  const options: EChartsOption = {
    ...chartOptions,
    tooltip: {
      ...chartOptions.tooltip,
      padding: [5, 10],
      backgroundColor: Color.Endeavour,
      borderColor: Color.Endeavour,
      shadowBlur: 6,
      shadowOffsetY: 4,
      shadowColor: `${Color.Black}2E`, // 2E = 0.18 opacity
      textStyle: {
        fontFamily: "Cabin",
        color: Color.White,
        fontSize: 14,
        lineHeight: 17,
        fontWeight: 500,
      },
    },
  };

  return (
    <div className="h-[33vh] px-4 py-3 rounded-[9px] bg-endeavour/10 flex flex-col gap-7">
      <p className="text-cloudBurst text-sm leading-4 font-medium">{title}</p>
      <div className="grow h-0 relative">
        {isEmpty ? (
          <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            No data
          </p>
        ) : (
          <ReactECharts
            style={{ height: "100%", width: "100%" }}
            option={options}
          />
        )}
      </div>
    </div>
  );
};

export default RTMAnalysisChartWrapper;
