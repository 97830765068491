import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import UsersTable from "components/Tables/UsersTable/UsersTable";
import { IUser, IUserFilters } from "interfaces/User";
import { OrderDirection, PageSize } from "enums/Common";
import useSnackbar from "hooks/useSnackbar";
import PageTitleDescription from "components/PageTitleDescription/PageTitleDescription";
import DebouncedSearchField from "components/DebouncedSearchField/DebouncedSearchField";
import Button from "components/Buttons/Button/Button";
import UserModal from "components/Modals/UserModal/UserModal";
import StatusDropdown from "components/Dropdowns/StatusDropdown/StatusDropdown";
import { getIsInactiveFromSearchParams } from "helpers/CommonHelper";

const AdminDashboard = () => {
  const [searchParams] = useSearchParams();

  const { snackbarComponent, setSnackbarSuccess, setSnackbarError } =
    useSnackbar();

  const [filters, setFilters] = useState<IUserFilters>({
    _total_count: 1,
    page_size: PageSize.Default,
    page: 1,
    _order_by:
      searchParams.get("_order_by") || `created_on ${OrderDirection.Desc}`,
    withInactive: true,
    "email|name": searchParams.get("email|name") || undefined,
    isInactive: getIsInactiveFromSearchParams(searchParams),
  });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>(
    undefined
  );

  const handleUserModalSuccess = useCallback(
    (message: string) => {
      setSnackbarSuccess(message);
      setFilters((prev) => ({ ...prev, page: 1 }));
    },
    [setSnackbarSuccess]
  );

  return (
    <div className="h-full flex flex-col gap-5">
      <div className="flex flex-col gap-4 pr-5 xl:pr-0">
        <div className="flex flex-col gap-4 justify-between sm:flex-row">
          <PageTitleDescription
            className="w-full xl:w-fit"
            title="Admin"
            description="Manage existing users or create new ones."
          />

          <Button
            className="shrink-0 self-center"
            onClick={() => setIsCreateModalOpen(true)}
          >
            Create New User
          </Button>
        </div>

        <div className="flex flex-col justify-between items-stretch gap-4 sm:items-start md:flex-row lg:items-center">
          <DebouncedSearchField
            placeholder="Search by email or user name"
            value={filters["email|name"]}
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, page: 1, "email|name": value }))
            }
          />
          <StatusDropdown
            status={filters.isInactive}
            onChange={(isInactive) =>
              setFilters((prev) => ({ ...prev, page: 1, isInactive }))
            }
          />
        </div>
      </div>

      <div className="grow h-0">
        <UsersTable
          filters={filters}
          setFilters={setFilters}
          onViewProfileClick={(value) => setSelectedUser(value)}
        />
      </div>

      {(selectedUser || isCreateModalOpen) && (
        <UserModal
          initialData={selectedUser}
          onClose={() =>
            isCreateModalOpen
              ? setIsCreateModalOpen(false)
              : setSelectedUser(undefined)
          }
          onSuccess={handleUserModalSuccess}
          onError={setSnackbarError}
        />
      )}

      {snackbarComponent}
    </div>
  );
};

export default AdminDashboard;
