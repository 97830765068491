import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";
import ChatBubbleTwoToneIcon from "@mui/icons-material/ChatBubbleTwoTone";
import { ITableColumn } from "interfaces/Common";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import TableInputFilter from "components/TableInputFilter/TableInputFilter";
import TableDatepickerFilter from "components/TableDatepickerFilter/TableDatepickerFilter";
import useSnackbar from "hooks/useSnackbar";
import useFetchTableData from "hooks/useFetchTableData";
import Loading from "components/Loading/Loading";
import {
  createUrlParams,
  downloadFile,
  formatDashDate,
  formatName,
  getTimezone,
} from "helpers/CommonHelper";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import { IBilling, IBillingFilters } from "interfaces/Billing";
import BillingService from "services/BillingService";
import tableStyles from "styles/shared/table.module.scss";
import TooltipBox from "components/TooltipBox/TooltipBox";
import BillingStatusDropdown from "components/Dropdowns/BillingStatusDropdown/BillingStatusDropdown";
import styles from "./BillingTable.module.scss";
import { BillingStatus } from "enums/Billing";
import BillingReasonModal from "components/Modals/BillingReasonModal/BillingReasonModal";
import OrderSummaryModal from "components/Modals/OrderSummaryModal/OrderSummaryModal";
import TableRangeDatepickerFilter from "components/TableRangeDatepickerFilter/TableRangeDatepickerFilter";
import EllipsisTableCell from "components/EllipsisTableCell/EllipsisTableCell";
import { getBillingStatusLabel } from "helpers/BillingHelper";
import ReportsModal from "components/Modals/ReportsModal/ReportsModal";
import ReportsService from "services/ReportsService";
import { ITrendsAndSummaryReportParams } from "interfaces/Reports";
import { DateRange, MimeType } from "enums/Common";
import { RollupInterval } from "enums/Test";
import { mergePDFs } from "helpers/PDFHelper";

interface BillingTableProps {
  isSingleOrderHistory?: boolean;
  filters: IBillingFilters;
  setFilters: Dispatch<SetStateAction<IBillingFilters>>;
}

enum Modals {
  BillingReason = 1,
  OrderSummary = 2,
  SummaryReport = 3,
}

type IModalData =
  | { modal: Modals.BillingReason; billingId: number; status: BillingStatus }
  | { modal: Modals.OrderSummary; orderId: string }
  | { modal: Modals.SummaryReport; billing: IBilling };

const BillingTable = (props: BillingTableProps) => {
  const { isSingleOrderHistory, filters, setFilters } = props;

  const [, setSearchParams] = useSearchParams();

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  const { snackbarComponent, setSnackbarSuccess, setSnackbarError } =
    useSnackbar();
  const { data, setData, loading, scrollableParentRef, lastElementRef } =
    useFetchTableData<IBilling, IBillingFilters>({
      filters,
      onPageIncrement,
      fetchAPI: BillingService.getAll,
      onError: setSnackbarError,
    });

  const [modalData, setModalData] = useState<IModalData>();
  const [downloadingPdfId, setDownloadingPdfId] = useState<number>();

  // fewer columns are shown on single order billing history
  const tableColumns: ITableColumn[] = [
    ...(isSingleOrderHistory
      ? []
      : [
          { label: "Account", sortProperty: "client" },
          { label: "Patient", sortProperty: "lname,fname" },
          { label: "DOB", sortProperty: "dob" },
        ]),
    { label: "Billing Start", sortProperty: "from_date" },
    { label: "Billing Stop", sortProperty: "to_date" },
    { label: "Order ID", sortProperty: "order_num" },
    ...(isSingleOrderHistory
      ? []
      : [{ label: "Days Used", sortProperty: "days_cnt" }]),
    { label: "Status", sortProperty: "status" },
    ...(isSingleOrderHistory ? [] : [{ label: "" }]),
  ];

  useEffect(() => {
    const {
      page,
      page_size,
      _total_count,
      // push only props that can be filtered
      ...urlParams
    } = filters;

    setSearchParams(createUrlParams(urlParams), { replace: true });
  }, [filters, setSearchParams]);

  const updateBilling = (
    id: number,
    data: { status: BillingStatus; comment?: string }
  ) => {
    const { status, comment } = data;

    BillingService.update({ transactionId: id, status, comment })
      .then(() => {
        setData((prev) =>
          prev.map((x) =>
            x.id === id
              ? {
                  ...x,
                  status,
                  comment: comment ?? x.comment,
                }
              : x
          )
        );
        setSnackbarSuccess("The status has been successfully edited.");
      })
      .catch(() => setSnackbarError());
  };

  const handleStatusChange = (
    id: number,
    data: { status: BillingStatus | undefined; comment: string | undefined }
  ): void => {
    const { status, comment } = data;

    if (!status) return;

    // NotBilledOther status requires reason to be entered
    // Modal with reason box will open when pendingReasonId state is set
    if (status === BillingStatus.NotBilledOther) {
      setModalData({
        modal: Modals.BillingReason,
        billingId: id,
        status: BillingStatus.NotBilledOther,
      });
      return;
    }

    updateBilling(id, { status, comment });
  };

  const handleCommentModalConfirm = (
    id: number,
    data: { status: BillingStatus; comment: string }
  ): void => {
    updateBilling(id, data);
    setModalData(undefined);
  };

  const handleCloseModal = useCallback(() => {
    setModalData(undefined);
  }, []);

  const generatePDFName = (billing: IBilling) => {
    const { clientSn, fname, lname, fromDate, toDate } = billing;

    const items = [
      clientSn.replaceAll(" ", "_"),
      lname.replaceAll(" ", "_"),
      fname.replaceAll(" ", "_"),
      moment(fromDate).format("MMDDYY"),
      moment(toDate).format("MMDDYY"),
    ];

    return `${items.join("_").toUpperCase()}_Summary.pdf`;
  };

  const generateSummaryReportPromises = (billing: IBilling) => {
    const { patientId, fromDate, toDate, testIds, orderId } = billing;

    // API expects endDate to be incremented by 1 day in order to include it
    const endDate = moment(toDate).add(1, "day").format("YYYY-MM-DD");
    const createdOn = `${fromDate}${DateRange.Separator}${endDate}`;

    const commonParams: Omit<ITrendsAndSummaryReportParams, "testId"> = {
      patientId,
      createdOn,
      _timezone: getTimezone(),
      rollup: RollupInterval.Day,
      orderId,
    };

    return testIds.map((testId) =>
      ReportsService.getSummary({ ...commonParams, testId })
    );
  };

  const handleDownloadPDF = (billing: IBilling) => {
    setDownloadingPdfId(billing.id);

    const name = generatePDFName(billing);

    Promise.all(generateSummaryReportPromises(billing))
      .then(
        (responses) =>
          responses
            .map(({ request }) => request.response)
            .filter((x) => x.size > 0) // filter empty pdfs
      )
      .then((pdfs) => mergePDFs(pdfs))
      .then((pdf) => {
        const pdfFile = new File([pdf], name, { type: MimeType.PDF });
        const url = URL.createObjectURL(pdfFile);
        downloadFile(url, name);
      })
      .catch(() => setSnackbarError())
      .finally(() => setDownloadingPdfId(undefined));
  };

  return (
    <>
      <div
        className={`h-full relative -mr-5 ${styles.billing_table_wrapper} ${
          isSingleOrderHistory ? "" : styles.limit_cell_widths // no need to limit widths on single order billing (only 4 colums are shown)
        }`}
      >
        <TableContainer
          className={tableStyles.shared_table_container}
          ref={scrollableParentRef}
        >
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map(({ label, sortProperty }, index) => (
                  <TableCell key={index}>
                    {sortProperty ? (
                      <TableSortLabel
                        whiteTheme
                        label={label}
                        sortProperty={sortProperty}
                        orderBy={filters._order_by}
                        onSort={(value) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            _order_by: value,
                          }))
                        }
                      />
                    ) : (
                      label
                    )}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {!isSingleOrderHistory && (
                  <>
                    <TableCell>
                      <TableInputFilter
                        placeholder="Search by account"
                        value={filters.client}
                        onChange={(client) =>
                          setFilters((prev) => ({ ...prev, page: 1, client }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableInputFilter
                        placeholder="Search by patient name"
                        value={filters.name}
                        onChange={(name) =>
                          setFilters((prev) => ({ ...prev, page: 1, name }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableDatepickerFilter
                        removeNextDay
                        value={filters.dob}
                        onChange={(dob) =>
                          setFilters((prev) => ({ ...prev, page: 1, dob }))
                        }
                      />
                    </TableCell>
                  </>
                )}
                <TableCell>
                  <TableRangeDatepickerFilter
                    value={filters.fromDate}
                    onChange={(fromDate) =>
                      setFilters((prev) => ({ ...prev, page: 1, fromDate }))
                    }
                  />
                </TableCell>
                <TableCell>
                  <TableRangeDatepickerFilter
                    value={filters.toDate}
                    onChange={(toDate) =>
                      setFilters((prev) => ({ ...prev, page: 1, toDate }))
                    }
                  />
                </TableCell>
                <TableCell>
                  {/* already prefiltered by order on single order billing */}
                  {!isSingleOrderHistory && (
                    <TableInputFilter
                      isNumericField
                      placeholder="Search by ID"
                      value={filters.orderNum}
                      onChange={(orderNum) =>
                        setFilters((prev) => ({ ...prev, page: 1, orderNum }))
                      }
                    />
                  )}
                </TableCell>
                {!isSingleOrderHistory && <TableCell />}
                <TableCell>
                  <BillingStatusDropdown
                    value={filters.status}
                    onChange={(status) =>
                      setFilters((prev) => ({ ...prev, page: 1, status }))
                    }
                  />
                </TableCell>
                {!isSingleOrderHistory && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((billing, index) => (
                <TableRow
                  key={billing.id}
                  ref={index === data.length - 1 ? lastElementRef : null}
                >
                  {!isSingleOrderHistory && (
                    <>
                      <EllipsisTableCell text={billing.client} />
                      <EllipsisTableCell
                        text={formatName({
                          firstName: billing.fname,
                          lastName: billing.lname,
                        })}
                      />
                      <TableCell>{formatDashDate(billing.dob)}</TableCell>
                    </>
                  )}
                  <TableCell>{formatDashDate(billing.fromDate)}</TableCell>
                  <TableCell>{formatDashDate(billing.toDate)}</TableCell>
                  <TableCell>
                    <TooltipBox
                      content="Click to see Order Summary"
                      offsetX={10}
                      offsetY={10}
                    >
                      <button
                        className="hover:text-pelorous hover:underline"
                        onClick={() =>
                          setModalData({
                            modal: Modals.OrderSummary,
                            orderId: billing.orderId,
                          })
                        }
                      >
                        {`${billing.clientSn}-${billing.orderNum}`}
                      </button>
                    </TooltipBox>
                  </TableCell>
                  {!isSingleOrderHistory && (
                    <TableCell>{billing.daysCnt}</TableCell>
                  )}
                  <TableCell className="relative">
                    {/* this cell is read only on single order billing history */}

                    {isSingleOrderHistory ? (
                      getBillingStatusLabel(billing.status)
                    ) : (
                      <BillingStatusDropdown
                        isInCell
                        value={billing.status}
                        onChange={(value) =>
                          handleStatusChange(billing.id, {
                            status: value,
                            comment: billing.comment ?? undefined,
                          })
                        }
                      />
                    )}

                    {billing.comment && (
                      <TooltipBox
                        content={
                          isSingleOrderHistory
                            ? billing.comment
                            : "Click to view or edit Reason"
                        }
                        offsetX={-20}
                      >
                        <button
                          className={styles.comment_btn}
                          onClick={() =>
                            isSingleOrderHistory
                              ? undefined
                              : setModalData({
                                  modal: Modals.BillingReason,
                                  billingId: billing.id,
                                  status: billing.status,
                                })
                          }
                        >
                          <ChatBubbleTwoToneIcon />
                        </button>
                      </TooltipBox>
                    )}
                  </TableCell>
                  {!isSingleOrderHistory && (
                    <TableCell className="relative">
                      <TooltipBox
                        content={
                          !billing.testIds.length ? "No recorded tests" : ""
                        }
                      >
                        <span>
                          <div
                            className={`flex items-center h-5 gap-2 ${
                              !billing.daysCnt
                                ? "opacity-50 pointer-events-none"
                                : ""
                            }`}
                          >
                            <TooltipBox content="View PDF">
                              <button
                                onClick={() =>
                                  setModalData({
                                    modal: Modals.SummaryReport,
                                    billing,
                                  })
                                }
                              >
                                <span className="icon_svg icon_view_pdf" />
                              </button>
                            </TooltipBox>
                            {billing.id !== downloadingPdfId ? (
                              <TooltipBox content="Download PDF">
                                <button
                                  className="pt-1.5"
                                  onClick={() => handleDownloadPDF(billing)}
                                >
                                  <span className="icon_svg icon_download_pdf" />
                                </button>
                              </TooltipBox>
                            ) : (
                              <div className="w-[27px] h-[28.5px] flex items-center justify-center">
                                <CircularProgress size={17} />
                              </div>
                            )}
                          </div>
                        </span>
                      </TooltipBox>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {!loading && !data.length && (
          <div className="mt-7">
            <NoTableItems icon="icon_search" message="No results found" />
          </div>
        )}

        <Loading loading={loading} />

        {modalData?.modal === Modals.BillingReason && (
          <BillingReasonModal
            initialComment={
              data.find((x) => x.id === modalData.billingId)?.comment ?? null
            }
            newStatus={modalData.status}
            onConfirm={(data) =>
              handleCommentModalConfirm(modalData.billingId, data)
            }
            onClose={() => setModalData(undefined)}
          />
        )}

        {modalData?.modal === Modals.OrderSummary && (
          <OrderSummaryModal
            orderId={modalData.orderId}
            onError={setSnackbarError}
            onClose={handleCloseModal}
          />
        )}

        {modalData?.modal === Modals.SummaryReport && (
          <ReportsModal
            fileName={generatePDFName(modalData.billing)}
            reportPromises={generateSummaryReportPromises(modalData.billing)}
            onError={setSnackbarError}
            onClose={handleCloseModal}
          />
        )}
      </div>

      {snackbarComponent}
    </>
  );
};

export default BillingTable;
