import { EChartsOption } from "echarts";
import moment from "moment";
import { Color } from "enums/Common";

interface IMonthAndYearParams<T> {
  data: T[];
  yearKey: keyof T;
  monthKey: keyof T;
}

// in time ascending order
export const sortMonthAndYearData = <T>(
  params: IMonthAndYearParams<T>
): T[] => {
  const { data, monthKey, yearKey } = params;

  return [...data].sort((a, b) =>
    a[yearKey] !== b[yearKey]
      ? (a[yearKey] as number) - (b[yearKey] as number)
      : (a[monthKey] as number) - (b[monthKey] as number)
  );
};

export const getMonthAndYearXValues = <T>(
  params: IMonthAndYearParams<T>
): string[] => {
  const { data, monthKey, yearKey } = params;

  return data.map(
    ({ [monthKey]: m, [yearKey]: y }) =>
      moment({ year: y as number, month: (m as number) - 1 }).format("MMM-YY") // subtract 1 from month because moment indexes months from 0 (0 - january, 11 - december)
  );
};

// calculates zoom slider so it shows last 12 months
export const get12MonthsDataZoomParams = (params: {
  data: unknown[];
  bottom: number;
}): EChartsOption["dataZoom"] => {
  const numberOfMonthsToShow = 12;

  const { data, bottom } = params;

  // no need to show slider if there is smaller amount of data
  if (data.length <= numberOfMonthsToShow) return undefined;

  const zoomStart = 100 - (numberOfMonthsToShow * 100) / data.length + 1;

  return [
    {
      type: "slider",
      xAxisIndex: 0,
      filterMode: "empty",
      height: 6,
      bottom,
      start: zoomStart,
      end: 100,
      showDetail: false,
      backgroundColor: `${Color.Endeavour}33`, // 33 -> 0.2 opacity
      fillerColor: Color.Pelorous,
      moveHandleStyle: { opacity: 0 },
    },
  ];
};
