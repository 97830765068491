import { EChartsOption } from "echarts";
import RTMAnalysisChartWrapper from "components/RTMAnalysis/RTMAnalysisChartWrapper/RTMAnalysisChartWrapper";
import { Color } from "enums/Common";
import { IRTMAnalysisData } from "interfaces/KPI";
import {
  get12MonthsDataZoomParams,
  getMonthAndYearXValues,
  sortMonthAndYearData,
} from "helpers/KPIChartHelper";

interface ClaimStatusChartProps {
  data: IRTMAnalysisData["claims"];
}

const ClaimStatusChart = ({ data }: ClaimStatusChartProps) => {
  const sortedData = sortMonthAndYearData({
    data,
    monthKey: "m",
    yearKey: "y",
  });

  const xValues = getMonthAndYearXValues({
    data: sortedData,
    monthKey: "m",
    yearKey: "y",
  });
  const submittedValues = sortedData.map(({ sent }) => sent);
  const unsubmittedValues = sortedData.map((x) => x.new); // cannot be destructured because "new" is reserved word in JS

  const getOptions = (): EChartsOption => {
    const dataZoom = get12MonthsDataZoomParams({ data, bottom: 5 });

    return {
      tooltip: {
        trigger: "item",
        formatter: "{a}<br/>{b}: {c}", // name: value
      },
      grid: {
        top: 5,
        bottom: dataZoom ? 35 : 20,
        left: 5,
        right: 0,
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 12,
            lineHeight: 15,
            fontFamily: "Cabin",
            color: Color.Nero,
            rotate: 90,
          },
          data: xValues,
        },
      ],
      yAxis: [
        {
          type: "value",
          minInterval: 1,
          axisLabel: {
            fontSize: 10,
            lineHeight: 13,
            fontFamily: "Cabin",
            color: Color.Nero,
          },
          splitLine: {
            lineStyle: { color: Color.VeryLightGrey },
          },
          axisTick: { show: true },
        },
      ],
      series: [
        {
          name: "Submitted",
          barWidth: 6,
          type: "bar",
          color: Color.Endeavour,
          emphasis: {
            focus: "series",
          },
          data: submittedValues,
        },
        {
          name: "Unsubmitted",
          barWidth: 6,
          type: "bar",
          color: Color.Tumbleweed,
          emphasis: {
            focus: "series",
          },
          data: unsubmittedValues,
        },
      ],
      legend: {
        icon: "square",
        itemWidth: 12,
        bottom: dataZoom ? 5 : -8,
        itemGap: 42,
        textStyle: {
          fontSize: 12,
          lineHeight: 15,
          fontFamily: "Cabin",
          color: Color.Nero,
        },
      },
      dataZoom,
    };
  };

  return (
    <RTMAnalysisChartWrapper
      key={data.length}
      isEmpty={!data.length}
      title="RTM Claim Status"
      chartOptions={getOptions()}
    />
  );
};

export default ClaimStatusChart;
