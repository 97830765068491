import { useCallback, useState } from "react";
import { generatePath, Link, useParams } from "react-router-dom";
import { OrderDirection, PageSize } from "enums/Common";
import { ITestNote, ITestNoteFilters } from "interfaces/Test";
import TestService from "services/TestService";
import useFetchTableData from "hooks/useFetchTableData";
import useSnackbar from "hooks/useSnackbar";
import Loading from "components/Loading/Loading";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import Button from "components/Buttons/Button/Button";
import ConfirmationDialog from "components/Modals/ConfirmationDialog/ConfirmationDialog";
import TestNoteModal from "components/Modals/TestNoteModal/TestNoteModal";
import TestNoteItem from "components/TestNoteItem/TestNoteItem";
import { URLRoutes } from "enums/Routing";

enum Modals {
  Add = 1,
  Edit = 2,
  Delete = 3,
}

type IModalData =
  | { modal: Modals.Add }
  | { modal: Modals.Edit | Modals.Delete; noteId: string };

const PatientTestNotes = () => {
  const { testId, clinicianId, patientId } = useParams();

  const { snackbarComponent, setSnackbarError, setSnackbarSuccess } =
    useSnackbar();

  const [filters, setFilters] = useState<ITestNoteFilters>({
    _order_by: `created_on ${OrderDirection.Desc}`,
    page: 1,
    page_size: PageSize.Default,
    _total_count: 1,
    orderItemIterationId: testId,
  });
  const [modalData, setModalData] = useState<IModalData>();

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  const { data, loading, scrollableParentRef, lastElementRef } =
    useFetchTableData<ITestNote, ITestNoteFilters>({
      fetchAPI: TestService.getNotes,
      onPageIncrement,
      filters,
      onError: setSnackbarError,
    });

  const selectedNoteForEdit =
    modalData?.modal === Modals.Edit
      ? data.find((x) => x.id === modalData.noteId)
      : undefined;

  const refetchData = () => {
    setFilters((prev) => ({ ...prev, page: 1 }));
  };

  const handleSuccessfullyChangedNote = (message: string) => {
    refetchData();
    setSnackbarSuccess(message);
  };

  const handleDeleteNote = (noteId: string) => {
    TestService.deleteNote(noteId)
      .then(() =>
        handleSuccessfullyChangedNote("Note has been deleted successfully.")
      )
      .catch(() => setSnackbarError());

    setModalData(undefined);
  };

  return (
    <>
      <div className="h-full flex flex-col gap-4 pt-10 relative pr-5 xl:pr-0 xl:pt-0">
        <Button
          className="absolute right-5 -top-2.5 xl:right-0 xl:-top-12"
          onClick={() => setModalData({ modal: Modals.Add })}
        >
          Add Note
        </Button>

        {patientId && (
          <Link
            className="self-start flex items-center gap-2 text-cloudBurst"
            to={
              clinicianId
                ? generatePath(URLRoutes.ClinicianPatientResults, {
                    clinicianId,
                    patientId,
                  })
                : generatePath(URLRoutes.PatientResults, {
                    patientId,
                  })
            }
          >
            <span className="icon_svg icon_navigate_arrow !bg-cloudBurst" />
            Back to Results
          </Link>
        )}

        <div
          className={`grow h-0 pb-5 overflow-scroll ${
            data.length ? "border-t border-linkWater" : ""
          }`}
          ref={scrollableParentRef}
        >
          {data.map((note, index) => (
            <TestNoteItem
              key={note.id}
              ref={index === data.length - 1 ? lastElementRef : null}
              note={note}
              onEditBtnClick={() =>
                setModalData({ modal: Modals.Edit, noteId: note.id })
              }
              onDeleteBtnClick={() =>
                setModalData({ modal: Modals.Delete, noteId: note.id })
              }
            />
          ))}

          {!data.length && !loading && (
            <NoTableItems
              className="mt-10"
              message="Test doesn't have Notes."
            />
          )}
        </div>

        <Loading loading={loading} />

        {(modalData?.modal === Modals.Add ||
          modalData?.modal === Modals.Edit) && (
          <TestNoteModal
            onClose={() => setModalData(undefined)}
            onError={setSnackbarError}
            onSuccess={handleSuccessfullyChangedNote}
            {...(selectedNoteForEdit
              ? { initialData: selectedNoteForEdit }
              : { testId: testId! })}
          />
        )}

        {modalData?.modal === Modals.Delete && (
          <ConfirmationDialog
            title="Delete Note"
            content="Are you sure you want to delete Note?"
            onConfirm={() => handleDeleteNote(modalData.noteId)}
            onClose={() => setModalData(undefined)}
          />
        )}
      </div>
      {snackbarComponent}
    </>
  );
};

export default PatientTestNotes;
