import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { ITableColumn } from "interfaces/Common";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import Loading from "components/Loading/Loading";
import { createUrlParams, formatDate } from "helpers/CommonHelper";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import useSnackbar from "hooks/useSnackbar";
import useFetchTableData from "hooks/useFetchTableData";
import { IAccountDirectory, IAccountFilters } from "interfaces/Account";
import AccountService from "services/AccountService";
import { Auth, AccountAuth } from "recoil-states/auth-states";
import MenuOptions from "components/MenuOptions/MenuOptions";
import { IMenuOption } from "interfaces/Test";
import { Color } from "enums/Common";
import tableStyles from "styles/shared/table.module.scss";

interface AccountsTableProps {
  filters: IAccountFilters;
  setFilters: Dispatch<SetStateAction<IAccountFilters>>;
  handleSelectAccount: (value: IAccountDirectory) => void;
  handleSwitchAccount: (value: IAccountDirectory) => void;
}

const tableColumns: ITableColumn[] = [
  { label: "Account Name", sortProperty: "name" },
  { label: "Short Name", sortProperty: "short_name" },
  { label: "Created on", sortProperty: "created_on" },
  { label: "Products" },
  { label: "Status" },
  { label: "" },
  { label: "" },
  { label: "" },
];

const AccountsTable = (props: AccountsTableProps) => {
  const { filters, setFilters, handleSelectAccount, handleSwitchAccount } =
    props;

  const [, setSearchParams] = useSearchParams();

  const auth = useRecoilValue(Auth);
  const accountAuth = useRecoilValue(AccountAuth);

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  const { snackbarComponent, setSnackbarSuccess, setSnackbarError } =
    useSnackbar();
  const { data, setData, loading, scrollableParentRef, lastElementRef } =
    useFetchTableData<IAccountDirectory, IAccountFilters>({
      filters,
      onPageIncrement,
      fetchAPI: AccountService.getDirectory,
      onError: setSnackbarError,
    });

  useEffect(() => {
    const {
      page,
      page_size,
      _total_count,
      withInactive,
      // push only props that can be filtered
      ...urlParams
    } = filters;

    setSearchParams(createUrlParams(urlParams), { replace: true });
  }, [filters, setSearchParams]);

  const formatProductsLabel = (account: IAccountDirectory) => {
    const products = [
      account.hasLab ? "Research" : "",
      account.hasOrder ? "At Home" : "",
      account.hasProtocol ? "Remote" : "",
      account.hasToolkit ? "In Clinic" : "",
    ];

    const filteredProducts = products.filter((x) => x);

    return filteredProducts.length > 0 ? filteredProducts.join(", ") : "-";
  };

  const switchedAccountID = useMemo(
    () => accountAuth?.initialData?.user.clientId,
    [accountAuth]
  );

  const handleToggleActivation = (data: IAccountDirectory) => {
    const { id, isInactive } = data;
    const newIsInactive = !isInactive;

    AccountService.edit(id, { isInactive: newIsInactive })
      .then(() => {
        setData((prev) =>
          prev.map((item) =>
            item.id === id ? { ...item, isInactive: newIsInactive } : item
          )
        );

        setSnackbarSuccess(
          `Account is successfully ${
            newIsInactive ? "deactivated" : "activated"
          }.`
        );
      })
      .catch(() => setSnackbarError());
  };

  const getMenuOptions = (account: IAccountDirectory): IMenuOption[] => {
    const { id, isInactive } = account;

    return [
      {
        label: isInactive ? "Activate" : "Deactivate",
        color: isInactive ? Color.Pelorous : Color.Crimson,
        disabled: switchedAccountID === id,
        onClick: () => handleToggleActivation(account),
      },
    ];
  };

  return (
    <>
      <div className="h-full relative">
        <TableContainer
          className={tableStyles.shared_table_container}
          ref={scrollableParentRef}
        >
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map(({ label, sortProperty }, index) => (
                  <TableCell key={index}>
                    {sortProperty ? (
                      <TableSortLabel
                        label={label}
                        sortProperty={sortProperty}
                        orderBy={filters._order_by}
                        onSort={(value) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            _order_by: value,
                          }))
                        }
                      />
                    ) : (
                      label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((account, index) => {
                const { id, name, shortName, createdOn, isInactive } = account;

                return (
                  <TableRow
                    key={id}
                    ref={index === data.length - 1 ? lastElementRef : null}
                  >
                    <TableCell>{name}</TableCell>
                    <TableCell>{shortName ?? "-"}</TableCell>
                    <TableCell>{formatDate(createdOn)}</TableCell>
                    <TableCell>{formatProductsLabel(account)}</TableCell>
                    <TableCell>{isInactive ? "Inactive" : "Active"}</TableCell>
                    <TableCell className="w-[170px]">
                      <div className="flex justify-between items-center gap-7 w-[130px]">
                        <button
                          className="text-pelorous enabled:hover:underline disabled:opacity-50"
                          disabled={isInactive}
                          onClick={() => handleSelectAccount(account)}
                        >
                          Edit Account
                        </button>
                      </div>
                    </TableCell>
                    <TableCell className="w-[170px]">
                      <div
                        className={
                          "flex justify-between items-center gap-7 w-[130px]"
                        }
                      >
                        <button
                          className="text-pelorous enabled:hover:underline disabled:opacity-50"
                          disabled={
                            isInactive ||
                            switchedAccountID === id ||
                            auth?.initialData?.user.clientId === id
                          }
                          onClick={() => handleSwitchAccount(account)}
                        >
                          Switch Account
                        </button>
                      </div>
                    </TableCell>
                    <TableCell className="w-[52px]">
                      <MenuOptions options={getMenuOptions(account)} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Loading loading={loading} />

        {!loading && !data.length && (
          <div className="mt-7">
            <NoTableItems
              icon={filters["name"] ? "icon_search" : "icon_no_results"}
              message={
                filters["name"]
                  ? "No results found for"
                  : "No existing accounts."
              }
              search={filters["name"]}
            />
          </div>
        )}
      </div>

      {snackbarComponent}
    </>
  );
};

export default AccountsTable;
