import { useMemo, useState } from "react";
import {
  generatePath,
  useParams,
  matchPath,
  useLocation,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import LinkButton from "components/Buttons/LinkButton/LinkButton";
import { IOrderDetails } from "interfaces/Order";
import { URLRoutes } from "enums/Routing";
import Button from "components/Buttons/Button/Button";
import { hasOrderExpired } from "helpers/OrderHelper";
import CreateEditOrderModal from "components/Modals/CreateEditOrderModal/CreateEditOrderModal";
import useSnackbar from "hooks/useSnackbar";
import Loading from "components/Loading/Loading";
import { Auth, IsClientAdmin, IsSuperAdmin } from "recoil-states/auth-states";
import { UserType } from "enums/User";
import CancelOrderModal from "components/Modals/CancelOrderModal/CancelOrderModal";
import { OrderStatus } from "enums/Order";
import OrderVersionCards from "components/OrderVersionCards/OrderVersionCards";
import { INavItem } from "interfaces/Common";
import Navbar from "components/Navbar/Navbar";
import OrderHistory from "components/OrderHistory/OrderHistory";
import OrderBillingHistory from "components/OrderBillingHistory/OrderBillingHistory";

interface OrderComponentProps {
  order: IOrderDetails | undefined;
  loading: boolean;
  onSuccessfullyEdited: () => void;
}

enum Modal {
  Edit = 1,
  Cancel = 2,
  Renew = 3,
}

enum OrderTabs {
  Details = 1,
  History = 2,
  BillingHistory = 3,
}

const OrderComponent = (props: OrderComponentProps) => {
  const { order, loading, onSuccessfullyEdited } = props;

  const { clinicianId, orderId } = useParams();
  const { pathname } = useLocation();

  const { snackbarComponent, setSnackbarError } = useSnackbar();

  const [activeModal, setActiveModal] = useState<Modal | undefined>(undefined);
  const [activeTab, setAtiveTab] = useState<OrderTabs>(OrderTabs.Details);

  const auth = useRecoilValue(Auth);
  const isClientAdmin = useRecoilValue(IsClientAdmin);
  const isSuperAdmin = useRecoilValue(IsSuperAdmin);

  const navItems: INavItem[] = [
    {
      label: "Order",
      onClick: () => setAtiveTab(OrderTabs.Details),
      isActive: activeTab === OrderTabs.Details,
    },
    {
      label: "Order History",
      onClick: () => setAtiveTab(OrderTabs.History),
      isActive: activeTab === OrderTabs.History,
    },
    // billing history is available only to super admin role
    ...(isSuperAdmin
      ? [
          {
            label: "Billing History",
            onClick: () => setAtiveTab(OrderTabs.BillingHistory),
            isActive: activeTab === OrderTabs.BillingHistory,
          },
        ]
      : []),
  ];

  const showViewResultsBtn = !matchPath(
    URLRoutes.PatientOrderDetails,
    pathname
  );

  // Clinical staff account holders, Clinician accounts and BioMech admins have the ability to edit or cancel an unexpired Order.
  const canEditOrCancelOrder = useMemo(
    (): boolean =>
      order
        ? order.status === OrderStatus.Opened &&
          !hasOrderExpired(order) &&
          (isSuperAdmin || isClientAdmin || auth?.type === UserType.Clinician)
        : false,
    [order, auth, isClientAdmin, isSuperAdmin]
  );

  return (
    <>
      <div className="h-full relative flex flex-col gap-4">
        {order?.patientId && activeTab === OrderTabs.Details && (
          <div className="flex gap-5 self-center relative md:fixed md:top-16 md:right-5 md:portrait:top-3.5 lg:top-3.5">
            {canEditOrCancelOrder && (
              <>
                <Button
                  className="border-2 border-pelorous !bg-white !text-pelorous"
                  onClick={() => setActiveModal(Modal.Edit)}
                >
                  Edit Order
                </Button>
                <Button
                  className="!bg-crimson"
                  onClick={() => setActiveModal(Modal.Cancel)}
                >
                  Cancel Order
                </Button>
              </>
            )}

            {order.canBeRenewed && (
              <Button
                className="border-2 border-pelorous !bg-white !text-pelorous"
                onClick={() => setActiveModal(Modal.Renew)}
              >
                Renew Order
              </Button>
            )}

            {showViewResultsBtn && (
              <LinkButton
                to={
                  clinicianId
                    ? generatePath(URLRoutes.ClinicianPatientResults, {
                        clinicianId,
                        patientId: order.patientId,
                      })
                    : generatePath(URLRoutes.PatientResults, {
                        patientId: order.patientId,
                      })
                }
                className="bg-tropicalRainForest border-none !text-white"
              >
                View Results
              </LinkButton>
            )}
          </div>
        )}

        <div className="grow h-0 flex flex-col gap-4">
          {order && (
            <>
              <Navbar drawerTitle={`Order ${order.id}`} items={navItems} />

              <div className="grow h-0 pr-5 xl:pr-0">
                {activeTab === OrderTabs.Details && (
                  <OrderVersionCards order={order} />
                )}

                {activeTab === OrderTabs.History && orderId && (
                  <OrderHistory orderId={orderId} onError={setSnackbarError} />
                )}

                {activeTab === OrderTabs.BillingHistory && order.patientId && (
                  <OrderBillingHistory
                    orderId={order.id}
                    patientId={order.patientId}
                  />
                )}
              </div>
            </>
          )}
        </div>

        {(activeModal === Modal.Edit || activeModal === Modal.Renew) && (
          <CreateEditOrderModal
            isRenew={activeModal === Modal.Renew}
            initialOrder={order}
            onClose={() => setActiveModal(undefined)}
            onInitialDataFetchFail={setSnackbarError}
            onCompleted={onSuccessfullyEdited}
          />
        )}

        {order && activeModal === Modal.Cancel && (
          <CancelOrderModal
            orderId={order.id}
            onClose={() => setActiveModal(undefined)}
            onEditBtnClick={() => setActiveModal(Modal.Edit)}
            onSuccess={onSuccessfullyEdited}
          />
        )}

        <Loading loading={loading} />
      </div>

      {snackbarComponent}
    </>
  );
};

export default OrderComponent;
