import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { Link, generatePath, useSearchParams } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { ISensor, ISensorFilters } from "interfaces/Sensor";
import useSnackbar from "hooks/useSnackbar";
import useFetchTableData from "hooks/useFetchTableData";
import SensorService from "services/SensorService";
import { ITableColumn } from "interfaces/Common";
import { createUrlParams, formatName } from "helpers/CommonHelper";
import Loading from "components/Loading/Loading";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import { formatSensorStatusLabel } from "helpers/SensorHelper";
import { URLRoutes } from "enums/Routing";
import tableStyles from "styles/shared/table.module.scss";

interface SensorsTableProps {
  filters: ISensorFilters;
  setFilters: Dispatch<SetStateAction<ISensorFilters>>;
}

const tableColumns: ITableColumn[] = [
  { label: "Sensor", sortProperty: "name" },
  { label: "Assignment" },
  { label: "Assigned to", sortProperty: "patient_ln" },
  { label: "Account", sortProperty: "client" },
  { label: "Status", sortProperty: "status_id" },
  { label: "" },
];

const SensorsTable = ({ filters, setFilters }: SensorsTableProps) => {
  const [, setSearchParams] = useSearchParams();

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  const { snackbarComponent, setSnackbarError } = useSnackbar();
  const { data, loading, scrollableParentRef, lastElementRef } =
    useFetchTableData<ISensor, ISensorFilters>({
      filters,
      onPageIncrement,
      fetchAPI: SensorService.getAll,
      onError: setSnackbarError,
    });

  useEffect(() => {
    const {
      page,
      page_size,
      _total_count,
      // push only props that can be filtered
      ...urlParams
    } = filters;

    setSearchParams(createUrlParams(urlParams), { replace: true });
  }, [filters, setSearchParams]);

  return (
    <>
      <div className="h-full relative">
        <TableContainer
          className={`${tableStyles.shared_table_container} ${tableStyles.padding_16_20}`}
          ref={scrollableParentRef}
        >
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map(({ label, sortProperty }) => (
                  <TableCell key={label}>
                    {sortProperty ? (
                      <TableSortLabel
                        label={label}
                        sortProperty={sortProperty}
                        orderBy={filters._order_by}
                        onSort={(value) =>
                          setFilters((prev) => ({
                            ...prev,
                            page: 1,
                            _order_by: value,
                          }))
                        }
                      />
                    ) : (
                      label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(
                (
                  {
                    id,
                    name,
                    patientId,
                    patientFn,
                    patientLn,
                    client,
                    statusId,
                  },
                  index
                ) => (
                  <TableRow
                    key={id}
                    ref={index === data.length - 1 ? lastElementRef : null}
                  >
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      {patientId ? "Assigned" : "Unnassigned"}
                    </TableCell>
                    <TableCell>
                      {formatName({
                        firstName: patientFn,
                        lastName: patientLn,
                      })}
                    </TableCell>
                    <TableCell>{client || "-"}</TableCell>
                    <TableCell>{formatSensorStatusLabel(statusId)}</TableCell>
                    <TableCell className="w-32">
                      <Link
                        className="text-pelorous hover:underline"
                        to={generatePath(URLRoutes.SensorDetails, {
                          sensorId: id,
                        })}
                      >
                        View Details
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {!loading && !data.length && (
          <div className="mt-7">
            <NoTableItems
              icon={
                filters["name|patient_fn|patient_ln"] || filters.statusId
                  ? "icon_search"
                  : "icon_no_results"
              }
              message={
                filters.statusId && !filters["name|patient_fn|patient_ln"]
                  ? "No sensors with selected status"
                  : "No added sensors"
              }
              search={filters["name|patient_fn|patient_ln"]}
            />
          </div>
        )}

        <Loading loading={loading} />
      </div>
      {snackbarComponent}
    </>
  );
};

export default SensorsTable;
